import React, { useContext } from "react";
import "../../AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import AdminLayout from "../../../../hoc/AdminLayout";
import { MyContext } from "../../../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ProfileComponent from "../../../../components/ProfileComponent";
import 'react-toastify/dist/ReactToastify.css';


const UserTimeOffDetails = ({ newUser, editTab, handleClose }) => {
    const navigate = useNavigate();

    const { setActivTab, setNewUser } = useContext(MyContext);
    console.log("newUser", newUser)

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }


    // config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const [policy, setPolicy] = useState([]);
    const [allPolicy, setAllPolicy] = useState([]);
    const [remaningPolicy, setRemaningPolicy] = useState([]);
    const [selectdPolicy, setSelectdPolicy] = useState("");


    console.log("remaningPolicy", remaningPolicy)
    console.log("policy", policy)

    const getUserPolciy = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/get-policy-for-users/?userID=${newUser?._id}`,
                config
            );
            if (data) {
                setPolicy(data.allPolicy)
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
        }
    };

    const getAllPolciy = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/get-policy/?companyID=${companyID}`,
                config
            );
            if (data) {
                setAllPolicy(data.allPolicy)
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
        }
    };

    const handleAddPolicy = (e) => {


        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];

        if (e.target.value === "selectAll") {
            const newData = allPolicy.map((item) => ({
                userDetails: {
                    userID: newUser._id,
                    userName: newUser.name,
                    hireDate: newUser?.hireDate ? newUser?.hireDate : ""
                },
                date: formattedDate,
                alocatedTimeOff: item.alocatedTimeOff,
                totalHours: item.alocatedTimeOff.hours,
                usedHours: 0,
                remainingHours: item.alocatedTimeOff.hours,
                companyID: item.companyID,
                policyDetails: {
                    policyName: item.name,
                    policyID: item._id,
                    waitingPeriod: item.waitingPeriod,
                    negativeBalance: item.negativeBalance
                }
            }));

            setPolicy(newData);
            setSelectdPolicy("");
        } else {
            setSelectdPolicy(e.target.value)
            const addedPolicy = allPolicy.filter(item => item?.name === e.target.value);



            const newData = [{
                userDetails: {
                    userID: newUser._id,
                    userName: newUser.name,
                    hireDate: newUser?.hireDate ? newUser?.hireDate : ""
                },
                date: formattedDate,
                alocatedTimeOff: addedPolicy[0].alocatedTimeOff,
                totalHours: addedPolicy[0].alocatedTimeOff.hours,
                usedHours: 0,
                remainingHours: addedPolicy[0].alocatedTimeOff.hours,
                companyID: addedPolicy[0].companyID,
                policyDetails: {
                    policyName: addedPolicy[0].name,
                    policyID: addedPolicy[0]._id,
                    waitingPeriod: addedPolicy[0].waitingPeriod,
                    negativeBalance: addedPolicy[0].negativeBalance
                }
            }]
            setPolicy(prevPolicy => [...prevPolicy, ...newData]);
            setSelectdPolicy("")
        }
    }

    const removePolicyByName = (policyNameToRemove) => {
        const updatedPolicy = policy.filter(p => p.policyDetails.policyName !== policyNameToRemove);
        setPolicy(updatedPolicy);
    };

    const handleSaveData = async (e, next) => {
        try {
            const { data } = await axios.post(
                `https://dev.timetraker.com/backend/api/update-user-policy/?companyID=${companyID}&userID=${newUser._id}`,
                policy,
                config
            );
            if (data) {
                //     setAllPolicy(data.allPolicy)
                if (next) {
                    setActivTab("holiday")
                } else {
                    if (editTab) {
                        handleClose()
                    } else {
                        setActivTab("user")
                    }
                    setNewUser()
                    setAllPolicy()
                    setSelectdPolicy()
                    setRemaningPolicy()
                }
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
            timeOffErrorMessage()
        }
    }

    useEffect(() => {

        const policyNames = policy.map(p => p.policyDetails.policyName);
        const filteredPolicies = allPolicy.filter(p => !policyNames.includes(p.name));
        setRemaningPolicy(filteredPolicies);

    }, [policy, allPolicy])

    useEffect(() => {
        getUserPolciy()
        getAllPolciy()
    }, [newUser])



    const timeOffErrorMessage = () => {
        toast.error("Something went wrong!!", {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const timeOffSuccessMessage = () => {
        toast.success("Policy has been updated!!", {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (

        <Container fluid className="dash3" style={{ padding: "0" }}>


            {/* Row 2 */}
            <Row style={{ background: "#F1F1F1" }}>
                < ToastContainer />
                <Col md={12}>
                    <div
                        style={
                            editTab ? {
                                minWidth: "886px",
                                margin: "0px",
                                padding: "20px",
                                borderRadius: "10px",
                                minHeight: "500px",
                                background: "white",
                                boxShadow: "none"

                            }
                                : {
                                    minWidth: "886px",
                                    marginTop: "22px",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    minHeight: "500px",
                                    background: "white",
                                    boxShadow: "none"
                                }}
                        className="adduser">
                        <div style={{ paddingTop: "30px", justifyContent: "space-between" }} className="adduser-header">
                            <div style={{ display: "flex", gap: "10px" }}>
                                <button onClick={() => setActivTab("user")} style={{ background: "white" }} className="btn1">User Info</button>
                                <button className="btn1"> Time Off Info</button>
                                <button onClick={() => setActivTab("holiday")} style={{ background: "white" }} className="btn1">Holiday Info</button>
                            </div>

                            <div >
                                <button style={!editTab ? { display: "none" } : null} onClick={() => handleClose()} className="btn1">Close</button>
                            </div>
                        </div>

                        <div style={{ padding: "20px" }}>
                            <div className="user-tab-policy" >
                                <p>Policy Name</p>
                                <p>Balance <span style={{ marginLeft: "30px" }}>Delete</span></p>
                            </div>
                            {policy?.map((item, index) => {
                                return (
                                    <div className="user-tab-policy" key={index}>
                                        <p> {item?.policyDetails?.policyName} </p>
                                        <p>{item?.remainingHours}
                                            <i onClick={() => removePolicyByName(item?.policyDetails?.policyName)}
                                                style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginLeft: "84px" }}
                                                className="fa-sharp fa-solid fa-trash userIconsHover"
                                            ></i>
                                        </p>

                                    </div>
                                )
                            })}

                            <div style={{ margin: "20px 70px" }} className="role-border mb-5">
                                <label style={{alignItems:"center"}} className="lable_bold" htmlFor="">
                                    Policy 
                                    <span
                                    style={{
                                        color: "blue",
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        fontWeight: "400",
                                        marginLeft: "14px",
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{navigate("/time-off-policy")}}
                                    >Create Time Off</span>
                                </label>
                                <Form.Select
                                    required
                                    onChange={handleAddPolicy}
                                    autocomplete="off"
                                    name="role"
                                    className="role"
                                    aria-label="Default select example"
                                    value={selectdPolicy}
                                >
                                    <option value="">
                                        select
                                    </option>
                                    <option value="selectAll">Select All</option>
                                    {remaningPolicy?.map((item, index) => {
                                        return (
                                            <option key={index} value={item?.name}>
                                                {item?.name}
                                            </option>
                                        )
                                    })}
                                </Form.Select>


                            </div>
                            <div className="adduserBtn ">
                                <button onClick={(e) => handleSaveData(e, false)} className="btn5">
                                    Save
                                </button>
                                <button onClick={(e) => handleSaveData(e, true)} className="btn5">
                                    Save & Next
                                </button>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>


        </Container>

    );
};

export default UserTimeOffDetails;
