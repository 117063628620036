


import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import { Form } from "react-bootstrap";
import Switch from '@mui/material/Switch';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

const columns = [
    { id: 'project', label: 'Project', minWidth: 100 },
    { id: 'billable', label: 'Billable', minWidth: 170 },
    { id: 'description', label: 'Description', minWidth: 170 },
    {
        id: 'createdAt',
        label: 'Created Project',
        minWidth: 170,
        align: 'center'
    },
    { id: 'edit', label: 'Edit', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 }
];

// temporory data

const data = [
    {
        project: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        project: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        project: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ projectInfo, setProjectInfo, handleDataFromChild }) {

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
        var userName = adminloginInfo.name;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
        var userName = userLoginInfo.name;
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };


    const TimesheetDeleteNotfy = () => {
        toast.success('Your project has been deleted', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // console.log('taskInfo in table', projectInfo.project);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const [reversSort, setReversSort] = useState(true)
    const [sortedColumn, setSortedColumn] = useState(null);


    const handleChangeSort = (field) => {
        const sortedData = [...projectInfo].sort((a, b) => {

            const aValue = a[field];
            const bValue = b[field];

            let comparison = 0;
            if (field === 'date') {
                comparison = new Date(aValue) - new Date(bValue);
            } else if (typeof aValue === 'string') {
                comparison = aValue.localeCompare(bValue);
            } else if (typeof aValue === 'number') {
                comparison = aValue - bValue;
            } else if (typeof aValue === 'boolean') {
                // Handle boolean fields
                comparison = (aValue === bValue) ? 0 : (aValue ? 1 : -1);
            }

            return reversSort ? comparison : -comparison;
        });

        setProjectInfo(sortedData);
        setReversSort(prevToggle => !prevToggle);
        setSortedColumn(field)
    };



    const handleSwitchChange = async (e, projectId) => {
        try {
            const { data } = await axios.post(
                `https://dev.timetraker.com/backend/api/active-project/?projectId=${projectId}`,
                { checked: e.target.checked },
                config
            );
            if (data) {
                handleDataFromChild(data);
                ProjectActiveNotfy();
            }

        } catch (err) {
            console.log(err);
        }
    }

    const ProjectActiveNotfy = () => {
        toast.success('Project status has been modified', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        companyID: companyID
    });

    console.log("formData", formData)
    const handleDailogBoxOpen = async (item) => {
        // setDataNeedToEdit({
        //     _id: _id,
        //     description: description,
        //     task: task, 
        //     project: project, 
        //     date: date, 
        //     hours: hours
        // })
        setFormData({
            _id: item._id,
            project: item.project,
            billable: item.billable,
            description: item.description
        })
        handleClickOpen()
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            handleClose();
            const { data } = await axios.post(
                `https://dev.timetraker.com/backend/api/edit-project`,
                formData,
                config
            );

            if (data) {
                handleDataFromChild(data)
                TimesheetEditNotfy()
            }
        } catch (error) {
            console.log("error from post timesheet api", error);
        }
    };

    const TimesheetEditNotfy = () => {
        toast.success('Your Project has been updated', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" style={{ overflow: 'hidden' }}>
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label === "Edit" ? <div> {column.label} </div> :
                                        <div
                                            onClick={() => handleChangeSort(column.id)}
                                            style={{ display: "flex", cursor: "pointer" }}>
                                            <ArrowDropDownCircleIcon
                                                style={{
                                                    transform: column.id === sortedColumn && !reversSort ? "rotate(180deg)" : "rotate(0deg)",
                                                    margin: "0px 10px 0px 17px",
                                                }}
                                            />
                                            {column.label}
                                        </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'hidden' }}>
                        {projectInfo
                            ? (rowsPerPage > 0 ? projectInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : projectInfo)?.map((item, index) => {
                                const createdAt = new Date(item?.createdAt);

                                const options = {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false,
                                    timeZone: 'Asia/Kolkata'
                                };
                                function shortenString(str) {
                                    if (str?.length > 8) {
                                        return str.substring(0, 8) + '...';
                                    }
                                    return str;
                                }
                                const formattedDate = createdAt.toLocaleString('en-IN', options);
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell title={item?.hierarchy > 0 ? `:: ${item?.project}` : item?.project}>{shortenString(item?.hierarchy > 0 ? `:: ${item?.project}` : item?.project)}</TableCell>
                                        <TableCell>{item?.billable ? "Yes" : "No"}</TableCell>
                                        <TableCell title={item?.description ? item?.description : "Empty"} style={{ textAlign: 'center' }}>{shortenString(item?.description ? item?.description : "Empty")}</TableCell>
                                        <TableCell align="center">{formattedDate} </TableCell>
                                        <TableCell align="center"><i onClick={() => (handleDailogBoxOpen(item))}
                                            style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }} class="fa-solid fa-pen-to-square userIconsHover"></i></TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.status} onChange={(e) => { handleSwitchChange(e, item._id) }} />}</TableCell>
                                    </TableRow>
                                );
                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="center">{item?.task}</TableCell>
                                        <TableCell align="center">{item?.status}</TableCell>
                                        <TableCell align="center">{item?.createdAt} </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.active} onChange={(e) => { handleSwitchChange(e, item._id) }} />}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={projectInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="adduser " style={{ padding: "20px 50px", boxShadow: "none" }}>
                    <form
                        onSubmit={handleSubmit}
                        action=""
                        className="adduser-form"
                        style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                    >

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div className="form1">
                                <div className="mb-3 projectInput">
                                    <label className="lable_bold" htmlFor="">
                                        Project
                                        <i className="fa-sharp fa-light fa-asterisk"></i>
                                    </label>
                                    <input
                                        style={{ minWidth: "240px" }}
                                        required
                                        value={formData.project}
                                        onChange={handleChange}
                                        type="text"
                                        name="project"
                                        id=""
                                    />
                                </div>


                            </div>

                            <div className="form2 ">

                                <div style={{ marginBottom: "13px", marginLeft: "10px" }} className="role-border projectInput">
                                    <label className="lable_bold" htmlFor="">
                                        Billable
                                        <i className="fa-sharp fa-light fa-asterisk"></i>
                                    </label>
                                    <Form.Select
                                        required
                                        onChange={handleChange}
                                        value={formData.billable}
                                        name="billable"
                                        className="role"
                                        aria-label="Default select example"
                                        style={{ marginLeft: "0px" }}
                                    >
                                        <option value="">Select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Form.Select>

                                    {/* <label htmlFor="">Role</label>
                                    <input type="text" name="role" id="" /> */}
                                    {/* <i class="fa-solid fa-sort-down"></i> */}
                                </div>

                            </div>
                        </div>
                        <div style={{ width: "100%" }} className="role-border projectInput">
                            <label className="lable_bold" htmlFor="">
                                Description
                            </label>
                            <textarea
                                onChange={handleChange}
                                value={formData.description}
                                name="description"
                                className="project-textarea" style={{ width: "100%", height: "77px", marginBottom: "31px" }} ></textarea>
                        </div>
                        <div className="adduserBtn ">
                            <button style={{ marginBottom: "18px" }} type="submit" className="btn5">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </Dialog>
        </Paper>
    );
}
