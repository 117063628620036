import React, { useEffect, useState, useContext } from 'react'
import AdminLayout from '../../hoc/AdminLayout'
import { MyContext } from '../../context/MyProvider'
import { Col, Container, Row } from "react-bootstrap";
import ProfileComponent from "../../components/ProfileComponent";
import "./WhoIsWroking.css"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { blue } from '@mui/material/colors';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const WhoIsWroking = () => {

    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);
    const [allClockins, setAllClockins] = useState([])
    const [allClockinsForFilter, setAllClockinsForFilter] = useState([])
    const [selectedRowIds, setSelectedRowIds] = useState([])
    const [allCheck, setAllCheck] = useState(true)

    console.log("selectedRowIds", selectedRowIds)
    console.log("selectedRowIds2", allClockins)

    const handleClick = () => {
        setSideBarStatus(!sideBarStatus);
    }

    const screenWidth = window.innerWidth;
    const forPhoneScreenNoDisplay = screenWidth >= 840;

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    const companyID = adminloginInfo?.companyID || userLoginInfo?.companyID;
    const token = adminloginInfo?.token || userLoginInfo?.token;

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const getAllClockInData = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/get-all-clock-ins/?companyID=${companyID}`,
                config
            );
            if (data.noOneIsWorking || data?.clockIns?.length < 1) {
                successNotify("No one is working right now!!")
            } else {
                setAllClockinsForFilter(data?.clockIns);
                setAllClockins(data?.clockIns);
                setSelectedRowIds(data?.clockIns.map(item => item?._id));
            }
        } catch (error) {
            console.log('error', error)
            errorNotify("Something went wrong!!")
        }
    };

    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    useEffect(() => {
        getAllClockInData();
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    const handleChnageAllCheck = () => {
        setAllCheck(!allCheck);
        if (allCheck) {
            setAllClockins([]);
            setSelectedRowIds([]);
        } else {
            setAllClockins(allClockinsForFilter);
            setSelectedRowIds(allClockinsForFilter.map(item => item?._id));
        }
    };

    const handleCheck = (_id) => {
        setSelectedRowIds(prevSelectedRowIds => {
            const newSelectedRowIds = prevSelectedRowIds.includes(_id)
                ? prevSelectedRowIds.filter(id => id !== _id)
                : [...prevSelectedRowIds, _id];

            const filteredData = allClockinsForFilter.filter(item => newSelectedRowIds.includes(item._id));
            setAllClockins(filteredData);
            return newSelectedRowIds;
        });
    };

    const mapStyles = {
        height: '100%',
        width: '98%'
    };

    const defaultCenter = {
        lat: 0,
        lng: 0
    };

    return (
        <AdminLayout>
            <ToastContainer />
            <div className={sideBarStatus ? 'content_right_dashboard' : "none"} fluid style={sideBarStatus === true && !forPhoneScreenNoDisplay ? { display: 'none' } : { display: 'block' }}>
                <Container fluid className="dash3" style={{ padding: "0", minHeight: '100vh' }}>
                    {/* Row 1 */}
                    <Row>
                        <Col className='task-container'>
                            <div className="hamburgar" style={sideBarStatus ? { display: 'none' } : { display: 'block' }}>
                                <i onClick={handleClick} className='fas fa-bars'></i>
                            </div>
                            <div className="task-top">
                                <div className="task-header1">
                                    <h5 style={sideBarStatus ? { paddingLeft: "10px", paddingTop: "2px" } : { paddingTop: "4px" }}>Who's Working Now</h5>
                                </div>

                                <div className="profile-header2">
                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='who_is_working'>
                        <Col className='left_side_employee_col' lg={4}>
                            <div className='left_side_employee'>
                                <TableCell style={{ minWidth: "342px", fontWeight: 600 }} align="left">
                                    <Checkbox
                                        checked={allCheck}
                                        onChange={handleChnageAllCheck}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} /> Employee
                                </TableCell>

                                {allClockinsForFilter?.map((item, index) => (
                                    <TableCell key={index} style={{ minWidth: "342px" }} align="left">
                                        <Checkbox
                                            checked={selectedRowIds.includes(item._id)}
                                            onChange={() => handleCheck(item._id)}
                                            sx={{
                                                color: blue[800],
                                                '&.Mui-checked': {
                                                    color: blue[600],
                                                },
                                            }} /> {item?.userName}
                                    </TableCell>
                                ))}

                            </div>
                        </Col>

                        <Col className='map' lg={8}>
                            <LoadScript googleMapsApiKey="AIzaSyAqpYCOBOth8Hp7QFQUPukehB2ZDI_5Lfw">
                                <GoogleMap
                                    mapContainerStyle={mapStyles}
                                    zoom={allClockins.length > 0 ? 13 : 2}
                                    center={
                                        allClockins.length < 1 ? defaultCenter
                                            :
                                            {
                                                lat: allClockins[0]?.clockIn?.clockInLocation?.latitude,
                                                lng: allClockins[0]?.clockIn?.clockInLocation?.longitude
                                            }
                                    }
                                >
                                    {allClockins.map((location, index) => (
                                        <Marker key={index} position={{ lat: location?.clockIn?.clockInLocation?.latitude || 0, lng: location?.clockIn?.clockInLocation?.longitude || 0 }} />
                                    ))}
                                </GoogleMap>
                            </LoadScript>
                        </Col>
                    </Row>
                </Container>
            </div>
        </AdminLayout>
    )
}

export default WhoIsWroking
