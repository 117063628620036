import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import TeamEdit from './EditComponent/TeamEdit';

const columns = [
    { id: 'team', label: 'Team', minWidth: 100 },
    { id: 'members', label: 'Members', minWidth: 100 },
    {
        id: 'description',
        label: 'Description',
        minWidth: 170,
        align: 'center'
    },
    { id: 'Edit', label: 'Edit', minWidth: 50 },
    { id: 'Delete', label: 'Delete', minWidth: 50 }
];

// temporory data

const data = [
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ teamInfo, handleDataFromChild, allUsers }) {

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };



    const TimesheetDeleteNotfy = () => {
        toast.success('Your team has been deleted', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };



    const TeamUpdateNotfy = () => {
        toast.success('Team has been updated', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // console.log('taskInfo in table', projectInfo.project);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [formData, setFormData] = React.useState({ companyID: companyID });
    const [deSelectedArray, setDeSelectedArray] = React.useState([]);
    const [open, setOpen] = React.useState(false);


    const handleUpdateClick = async (item) => {
        setOpen(true);
        try {
            const { data } = await axios.post('https://dev.timetraker.com/backend/api/get-specific-users/', { members: item.members });

            if (data) {
                const itemData = {
                    name: item.name,
                    members: data,
                    _id: item._id
                }
                setFormData(itemData);

            }
        } catch (err) {
            console.log(err);
        }

    }
    const handleClose = () => {
        setOpen(false);
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const TeamExistNotfy = () => {
        toast.success('Team Already Exist', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const deleteData = async (itemID) => {
        try {
            const { data } = await axios.delete(`https://dev.timetraker.com/backend/api/delete-team/?teamID=${itemID}`, config)
            console.log(data)
            TimesheetDeleteNotfy()
            handleDataFromChild(data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleUnCheck = async (e) => {

        console.log(e.target.value);
        if (e.target.checked) {
            setDeSelectedArray(deSelectedArray.filter((item) => (item != e.target.value)));
        }
        else {

            setDeSelectedArray([...deSelectedArray, e.target.value]);
        }

    }



    const handleUpdate = async () => {

        try {
            const { data } = await axios.post(`https://dev.timetraker.com/backend/api/edit-team/?teamID=${formData._id}`, { name: formData.name, deSelectedArray: deSelectedArray, companyID: companyID }, config);
            if (data) {
                TeamUpdateNotfy();
                handleDataFromChild(data);
                setFormData({ companyID: companyID });
                setOpen(false);
            }
        } catch (err) {
            err.response.status === 422 && TeamExistNotfy();
            console.log(err);
            setFormData({ companyID: companyID });
            setOpen(false);
        }
    }


    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 360 }}>
                <Table stickyHeader aria-label="sticky table" style={{ overflow: 'hidden' }}>
                    <TableHead >
                        <TableRow className='for-style-table-header' >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'hidden' }}>
                        {teamInfo
                            ? teamInfo?.map((item, index) => {
                                return (
                                    <TableRow className='for-style-table-body' hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="center">{item?.name}</TableCell>
                                        <TableCell align="center">{item?.members.length}</TableCell>
                                        <TableCell align="center">{item?.description ? item?.description : "Empty"}  </TableCell>
                                        <TableCell align="center"><i onClick={() => (handleUpdateClick(item))} style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }} class="fa-solid fa-pen-to-square userIconsHover"></i></TableCell>
                                        <TableCell align="center">  <i onClick={() => (deleteData(item?._id))} style={{ color: 'red', fontSize: '20px' }} className="fa-sharp fa-solid fa-trash userIconsHover" ></i> </TableCell>

                                    </TableRow>
                                );
                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell>{item?.task}</TableCell>
                                        <TableCell>{item?.status}</TableCell>
                                        <TableCell align="center">{item?.createdAt}  </TableCell>
                                        <TableCell align="center">  <i onClick={() => (deleteData(item?._id))} style={{ color: 'red', fontSize: '20px' }} className="fa-sharp fa-solid fa-trash userIconsHover" ></i> </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={teamInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                    maxWidth="lg"
            >

                {/* <div style={{ width: "40vw", height: "70vh", backgroundColor: "white", padding: "20px", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                    <span style={{ fontWeight: "500", width: "100%", fontSize: "18px", display: "flex", justifyContent: "space-around", alignItems: "center" }}>Team Name :<Form.Control
                        className="input_tag "
                        value={formData.name}
                        onChange={handleChange}
                        name="name"
                        required
                        type="text"
                        style={{ width: "50%", height: "45px" }}
                    /></span>
                    <FormGroup style={{ maxHeight: "80%", width: "fit-content", overflowY: "auto", flexWrap: "nowrap" }}>
                        {formData?.members?.map((u) => (
                            <FormControlLabel control={<Checkbox checked={!deSelectedArray.includes(u?._id)} />} value={u?._id} onChange={handleUnCheck} label={u?.name} />
                        ))}
                    </FormGroup>
                    <button type="submit" onClick={handleUpdate} style={{ width: "110px", height: "31px", borderRadius: "10px", background: "#64E48E", border: "none", fontSize: "14px", fontWeight: "500", marginTop: "20px" }}>Update</button>
                </div> */}

                {allUsers && formData?.members && (
                    <TeamEdit
                        selectItem={formData}
                        user={allUsers}
                        handleClose={handleClose}
                        handleDataFromChild={handleDataFromChild}
                    />
                )}

            </Dialog>

        </Paper>
    );
}