import React, { useContext } from "react";
import "../AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import AdminLayout from "../../../hoc/AdminLayout";
import { MyContext } from "../../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ProfileComponent from "../../../components/ProfileComponent";

const CompanySettings = () => {
    const { sideBarStatus, setSideBarStatus, activTab, setActivTab, newUser, setNewUser } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }


    const [formData, setFromData] = useState({
        companyID: companyID,
        companyName: "",
        billingEmail: "",
        phone: "",
        companyLogo: null,
        email: "",
        contactPerson: "",
        timezone: ""
    });

    const [errors, setErrors] = useState({}); // State for validation errors

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    // Validation function
    const validate = () => {
        const newErrors = {};
        let isValid = true; // Flag to check overall validity

        if (!formData.companyName.trim()) {
            newErrors.companyName = "Company Name is required.";
            toast.error(newErrors.companyName); // Show error in toast
            isValid = false;
        }
        if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Valid Primary Email is required.";
            toast.error(newErrors.email); // Show error in toast
            isValid = false;
        }

        setErrors(newErrors);
        return isValid; // Return true if all validations passed
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : value, // Handle file input separately
        }));
    };

    const getSuperAdmin = async () => {
        try {
          const { data } = await axios.get(
            `https://dev.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
            config
          );
          setFromData(data.superAdmin?.[0])
        } catch (error) {
          console.log("error from get all user api", error);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) { // Validate before submitting
            try {
                const formDataWithFiles = new FormData();
                Object.keys(formData).forEach(key => {
                    formDataWithFiles.append(key, formData[key]);
                });

                const {data} = await axios.post(
                    `https://dev.timetraker.com/backend/api/update-company-details/?companyID=${companyID}`, // Replace with your backend route
                    formDataWithFiles, // Send form data
                    {
                        headers: {
                            ...config.headers,
                            'Content-Type': 'multipart/form-data', // Set content type for file upload
                        },
                    }
                );

                if(data.formatError){
                    toast.error("Invalid file type. Only JPEG and PNG are allowed.");
                }else if (data.sizeError){
                    toast.error("File size exceeds the 1 MB limit.");
                }else{
                toast.success("Company settings updated successfully!");
                window.location.reload();
                }
            } catch (error) {
                toast.error("Failed to update company settings. Please try again.");
            }
        } else {
            toast.error("Please fix the validation errors before submitting.");
        }
    };

useEffect(()=>{
    getSuperAdmin()
},[])


    return (
        <AdminLayout>
            < ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block" }
                }
            >
                <div
                    className="hamburgar"
                    style={sideBarStatus ? { display: "none" } : { display: "block" }}
                >
                    <i onClick={handleClick} className="fas fa-bars"></i>
                </div>
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col md={12} className="adduser-container">
                            <div className="adduser-top">
                                <div className="adduser-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Company Settings
                                    </h5>
                                </div>

                                <div className="adduser-header2">
                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Row 2 */}
                    <Row style={activTab === "user" ? { background: "#F1F1F1" } : { display: "none" }}>
                        <Col md={12}>
                            <div className="adduser">
                                <div className="adduser-header">
                                    <button style={{ width: "190px" }} className="btn1">Company Details</button>
                                </div>

                                <form
                                    action=""
                                    className="adduser-form"
                                    id="crateForm"
                                    autocomplete="off"
                                >
                                    <div className="form1">
                                        <div className="add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Company Name
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                required
                                                onChange={handleChange}
                                                type="text"
                                                name="companyName"
                                                id=""
                                                autocomplete="off"
                                                value={formData.companyName}
                                            />
                                        </div>
                                        <div className="add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Billing Email
                                            </label>
                                            <input
                                                
                                                onChange={handleChange}
                                                autocomplete="off"
                                                type="email"
                                                name="billingEmail"
                                                id="email_for_user"
                                                className="amount"
                                                value={formData.billingEmail}
                                            />
                                        </div>
                                        <div className="add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Phone
                                            </label>
                                            <input
                                                
                                                onChange={handleChange}
                                                autocomplete="off"

                                                type="number"
                                                name="phone"
                                                id=""
                                                value={formData.phone}
                                            />
                                        </div>
                                        <div className="add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Company Logo
                                            </label>
                                            <input
                                                
                                                className="fileSelect"
                                                onChange={handleChange}
                                                style={{
                                                    background: "#04542c",
                                                    color: "white",
                                                    padding: "6px 10px",
                                                    height: "45px"
                                                }}
                                                autocomplete="off"
                                                type="file"
                                                name="companyLogo"
                                                id=""
                                            />
                                        </div>


                                    </div>

                                    <div className="form2">
                                        <div className="add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Primary Email
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                required
                                                onChange={handleChange}
                                                autocomplete="off"
                                                type="email"
                                                name="email"
                                                id="email_for_user"
                                                className="amount"
                                                value={formData.email}
                                            />
                                        </div>
                                        <div className="role-border add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Contact person
                                            </label>
                                            <input
                                                required
                                                onChange={handleChange}
                                                autocomplete="off"
                                                type="text"
                                                name="contactPerson"
                                                id="email_for_user"
                                                className="amount"
                                                value={formData.contactPerson}
                                            />


                                        </div>

                                        <div className="add_user_input_margin_bottom company_settings_input">
                                            <label className="lable_bold" htmlFor="timezone">
                                                Time Zone
                                            </label>
                                            <Form.Select
                                                required
                                                onChange={handleChange}
                                                autoComplete="off"
                                                name="timezone"
                                                className="role"
                                                aria-label="Default select example"
                                                value={formData.timezone}
                                            >
                                                <option value="">Select Time Zone</option>
                                                <option value="UTC-12:00">UTC-12:00</option>
                                                <option value="UTC-11:00">UTC-11:00</option>
                                                <option value="UTC-10:00">UTC-10:00 (Hawaii)</option>
                                                <option value="UTC-09:00">UTC-09:00 (Alaska)</option>
                                                <option value="UTC-08:00">UTC-08:00 (Pacific Time)</option>
                                                <option value="UTC-07:00">UTC-07:00 (Mountain Time)</option>
                                                <option value="UTC-06:00">UTC-06:00 (Central Time)</option>
                                                <option value="UTC-05:00">UTC-05:00 (Eastern Time)</option>
                                                <option value="UTC-04:00">UTC-04:00 (Atlantic Time)</option>
                                                <option value="UTC-03:00">UTC-03:00 (Argentina, Brazil)</option>
                                                <option value="UTC-02:00">UTC-02:00 (Mid-Atlantic)</option>
                                                <option value="UTC-01:00">UTC-01:00 (Azores)</option>
                                                <option value="UTC+00:00">UTC+00:00 (Greenwich Mean Time)</option>
                                                <option value="UTC+01:00">UTC+01:00 (Central European Time)</option>
                                                <option value="UTC+02:00">UTC+02:00 (Eastern European Time)</option>
                                                <option value="UTC+03:00">UTC+03:00 (Moscow, Istanbul)</option>
                                                <option value="UTC+04:00">UTC+04:00 (Dubai)</option>
                                                <option value="UTC+05:00">UTC+05:00 (Pakistan)</option>
                                                <option value="UTC+05:30">UTC+05:30 (India Standard Time)</option>
                                                <option value="UTC+06:00">UTC+06:00 (Bangladesh)</option>
                                                <option value="UTC+07:00">UTC+07:00 (Vietnam)</option>
                                                <option value="UTC+08:00">UTC+08:00 (China, Singapore)</option>
                                                <option value="UTC+09:00">UTC+09:00 (Japan)</option>
                                                <option value="UTC+10:00">UTC+10:00 (Australia Eastern)</option>
                                                <option value="UTC+11:00">UTC+11:00 (Solomon Islands)</option>
                                                <option value="UTC+12:00">UTC+12:00 (New Zealand)</option>
                                            </Form.Select>
                                        </div>




                                    </div>

                                </form>
                                <div className="adduserBtn ">
                                    <button onClick={handleSubmit} className="btn5">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>

        </AdminLayout>
    );
};

export default CompanySettings;
