import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelectTask({ tasks, handleMultiChangeTask, reset, setReset }) {
  const [personName, setPersonName] = useState([]);

  useEffect(() => {
    if (reset) {
      setPersonName([]);
      setReset(false);
    }
  }, [reset]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    
    // Extract only the `_id` field from the selected task objects
    const selectedIds = typeof value === 'string' ? value.split(',') : value.map((task) => task._id);
    
    // Pass the array of _ids to the handler
    handleMultiChangeTask(selectedIds);
    
    // Update the selected state
    setPersonName(value);
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 250 }} style={{ margin: '0px' }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          className="project_select"
          renderValue={(selected) => {
            const nameArray = [];
            selected.map((s) => {
              const { task } = s;
              nameArray.push(task);
            });

            return nameArray.length === 0 ? 'Select' : nameArray.join(', ');
          }}
          MenuProps={MenuProps}
          style={{ height: '38px', margin: '0px', border: 'none' }}
        >
          {tasks?.map((u) => (
            <MenuItem key={u._id} value={u}>
              <Checkbox checked={personName.indexOf(u) > -1} />
              <ListItemText primary={u.task} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
