import React, { useState, useContext, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './LoginForm.css'
import { MyContext } from '../../context/MyProvider'
import timerTrakerlogo from "../../img/TimeTrakerLogo.png"
import signInWithIntuiteDefault from '../../img/Sign_in_transparent_btn_tall_default.svg'
import signInWithIntuiteHover from '../../img/Sign_in_blue_btn_tall_hover.svg'


const LoginForm = () => {


    const [hoverImg, setHoverImg] = useState(false)



    const { setLoading, setSendWelcome, setShowDemoAlert } = useContext(MyContext);

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    }

    const [formData, setFromData] = useState({});

    const navigate = useNavigate()



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };




    const login = async (formData) => {
        try {
            setLoading(true)
            const { data } = await axios.post(`https://dev.timetraker.com/backend/api/user-login`, formData, config)
            if (data.admin) {
                setShowDemoAlert(data.admin.firstLogin)
                const currentDate = new Date();
                data.admin.logTime = currentDate
                setLoading(false)
                localStorage.setItem('adminLoginInfo', JSON.stringify(data.admin));
                const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
                if (adminLoginInfo?.accessibility?.dashboard === true) {
                    if (data.admin.firstLogin === true) {
                        navigate('/set-up-account')
                    } else {
                        navigate('/admin-dashboard-v2')
                    }
                }
            } else {
                const currentDate = new Date();
                data.user.logTime = currentDate
                setLoading(false)
                localStorage.setItem('userLoginInfo', JSON.stringify(data.user));
                const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
                if (userLoginInfo) {
                    if(data.user.accessibility.myTimesheets === true){
                    navigate('/my-time')
                    }else if(data.user.accessibility.myExpenses === true){
                        navigate('/my-expense')
                    }else{
                        navigate('/profile')
                    }
                }
            }
        }
        catch (error) {
            setLoading(false)
            if (error?.response?.data?.result == "Lock") {
                alert('This account has been locked for next 30 minutes')
            } else {
                console.log("error from user login", error)
                alert('invalid email and password')
            }
        }
    }






    const handleSubmit = async (e) => {
        e.preventDefault();
        login(formData)

    }

    // sign in with intuite 

    const [dataFormQuick, setDataFormQuick] = useState("");


    let win;

    function authorizeUri() {


        axios
            .get(
                "https://dev.timetraker.com/backend/authUri",
                function (uri) {
                }
            )
            .then(function (authUri) {
                var parameters = "location=1,width=800,height=650";
                 win = window.open(authUri.data, "connectPopup", parameters);
   
            });
    }


    async function retrieveToken() {
        // Generate the authUri
        try {
  
            const res = await axios.get("https://dev.timetraker.com/backend/retrieveToken", config);
            if (res?.data?.token?.access_token) {
                setDataFormQuick(res?.data);
                
                if (win && !win.closed) {
                    win.close(); // Close the window
                }
                return

            } else {
                setTimeout(retrieveToken, 2000)
            }


        } catch (error) {
            console.log("error", error)
        }
    }



    const signInWithIntuite = (e) => {
        e.preventDefault();
        retrieveToken()
        authorizeUri();
    }

    const oauthClientNull = async () => {
        try {
            await axios.get(
                `https://dev.timetraker.com/backend/oauthClient-null`,
                config
            );
        } catch (error) {
            console.log("error from getAllBankAccount", error);
        }
    }

    useEffect(() => {
   

        const getUserInfoFormQB = async () => {
            setLoading(true)
            try {
                const { data } = await axios.get('https://dev.timetraker.com/backend/getUserInfoFormQB', config)
                if (data.notVarifyed) {
                    setLoading(false)
                    alert('Your email address is not varifyed in QuickBooks')
                }
                if (data.loginData) {

                    saveTokenInBackend()

                    async function saveTokenInBackend() {
                      
                        oauthClientNull()

                        try {
                            const saveQBData = await axios.post(
                                `https://dev.timetraker.com/backend/api/update-qb-token?companyID=${data.loginData._id}`,
                                dataFormQuick,
                                config
                            );
                            if (saveQBData) {
                                localStorage.setItem('adminLoginInfo', JSON.stringify(data.loginData));
                                const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
                                setLoading(false)
                                if (adminLoginInfo?.accessibility?.dashboard === true) {
                                    if (adminLoginInfo?.firstLogin === true) {
                                        navigate('/set-up-account')
                                    } else {
                                        navigate('/admin-dashboard-v2')
                                    }
                                }
                            }
                        } catch (error) {
                            setLoading(false)
                            console.log("error from saveTokenInBackend", error);
                        }
                    }





                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                alert('not able to login please try again')
            }
        }



        const checkRealmId = async () => {
            setLoading(true)
            try {
                const { data } = await axios.get(`https://dev.timetraker.com/backend/api/sign-in-with-intuite/?realmId=${dataFormQuick.token.realmId}`, config)
                if (data.superAdmin) {
                    setLoading(false)
                    localStorage.setItem('adminLoginInfo', JSON.stringify(data.superAdmin));
                    const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
                    if (adminLoginInfo?.accessibility?.dashboard === true) {
                        if (adminLoginInfo?.firstLogin === true) {
                            navigate('/set-up-account')
                        } else {
                            navigate('/admin-dashboard-v2')
                        }
                    }
                }
                if (data.message == 'notAuthorized') {
                    getUserInfoFormQB()
                }
            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }
        checkRealmId()
    }, [dataFormQuick])


    const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
    const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
    //   console.log("adminloginInfo==========>", adminloginInfo)
    if (adminloginInfo) {
        var logTime = adminloginInfo.logTime
    }
    else if (userLoginInfo) {
        var logTime = userLoginInfo.logTime
    }

    useEffect(() => {
        const currentDate = JSON.parse(JSON.stringify(new Date()));


        const time1 = new Date(currentDate);
        const time2 = new Date(logTime);
        const timeDifferenceInMillis = Math.abs(time1 - time2);
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInMillis / (1000 * 60)); // converting milliseconds to minutes



        if (timeDifferenceInMinutes > 29) {
            localStorage.removeItem("adminLoginInfo");
            localStorage.removeItem("userLoginInfo");
            window.location.reload();
            navigate("/login")
        } else {
            const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
            const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
            setSendWelcome(true)
            if (adminloginInfo) {
                navigate('/admin-dashboard-v2')
            }

            if (userLoginInfo) {
                navigate('/my-time')
            }
        }
    }, [])

    // useEffect(() => {
    //     const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    //     const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    //     if (adminloginInfo) {
    //         navigate('/admin-dashboard-v2')
    //     }

    //     if (userLoginInfo) {
    //         navigate('/my-time')
    //     }
    // }, [])

    return (
        <div className='login_form_main_parent' >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {/* <h3 className='logo_right_log'>Logo</h3> */}
                <img style={{ width: '70px' }} src={timerTrakerlogo} alt='' />

                <p className='right_heading_log '>Simplify your time and expense management today.</p>
                {/* <p className='right_heading_log2'>No credit card required. Cancel anytime.</p> */}
            </div>
            <div className='form_parent_log'>
                <Form onSubmit={handleSubmit} className='mt-4' >
                    <Row>
                        <Form.Group className="mb-1 form-group-email" controlId="formGroupEmail">
                            <Form.Label>Email<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                            <Form.Control onChange={handleChange} name="email" type="email" />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group className='form-group-email' as={Col} controlId="formGroupEmail">
                            <Form.Label>Password<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                            <Form.Control onChange={handleChange} name="password" type="password" />
                        </Form.Group>
                    </Row>

                    <div className="d-grid headBtn1">
                        <Button type='submit'>Login</Button>
                        <Button style={{ border: '1px solis #64E48E', marginTop: "10px" }} onClick={() => { navigate('/forgot-password') }} className='btnhover'>Forgot Password?</Button>
                        {/* <Button style={{ border: '1px solis #64E48E' }} onClick={signInWithIntuite} className=' btnhover btnhover-intuite'>Sign in With Intuite</Button> */}
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img onMouseEnter={() => (setHoverImg(true))} style={{ height: "32px", cursor: "pointer", marginTop: "10px" }} onMouseLeave={() => (setHoverImg(false))} onClick={signInWithIntuite} src={hoverImg ? signInWithIntuiteHover : signInWithIntuiteDefault} />
                        </div> 
                    </div>
                    <div className='head-bottom-text1'>
                        <p className='text  mt-1' style={{ fontSize: '15px', marginBottom: "0px" }} >Already have an account ?  <span style={{ cursor: 'pointer', fontFamily: 'Inter', fontSize: '15px' }} onClick={() => { navigate('/signup') }}>Sign up here</span> </p>

                    </div>

                </Form>
            </div>
            <div>
                <p onClick={() => { navigate("/need-assistance") }} className='need_ass' style={{ fontSize: "16px", fontFamily: 'Inter', marginTop: "0px" }}> <span style={{ color: "#2385DF", cursor: 'pointer', fontFamily: 'Inter' }}> Need Assistance ?</span></p>
            </div>



        </div>
    )
}

export default LoginForm
