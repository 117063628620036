import React, { useState, useEffect, useContext } from "react";
import "../Quickbooks.css";
import axios from "axios";
import { MyContext } from "../../../context/MyProvider";
import AdminLayout from "../../../hoc/AdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import Loder from "../../../components/Loder";
import ProfileComponent from "../../../components/ProfileComponent";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const Sage = () => {



    const [loading, setLoading] = useState(false)



    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    const clockInTimeForSage = JSON.parse(localStorage.getItem("clockInTimeForSage"));

    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var TTtoken = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var TTtoken = userLoginInfo?.token
    }


    var config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${TTtoken}`
        },
    };

    const [connected, setConnected] = useState(false)
    const [clockInTime, setClockInTime] = useState(clockInTimeForSage)

    const [formData, setFormData] = useState({
        apiKey: "",
        subdomain: ""
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    async function handleSubmit(e) {
        e.preventDefault()
        setFormData({
            apiKey: "",
            subdomain: ""
        })
        setLoading(true)
        try {
            let { data } = await axios.post(
                `https://dev.timetraker.com/backend/api/sage/authUri/`,
                {
                    companyID: companyID,
                    apiKey: formData.apiKey,
                    subdomain: formData.subdomain
                },
                config
            );
            setLoading(false)
            setConnected(true)
        } catch (error) {
            setLoading(false)
            setConnected(false)
            errorNotify("There is some error please try again");
        }
    }

    async function getEmployee(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/employee-async/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }

    async function getCustomer(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/project-async/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }

    async function getWorkingPack(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/working-pack-async/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }

    async function downloadEmployee(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/employee-download/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }

    async function downloadProject(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/project-download/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }

    async function downloadWorkingPack(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/working-pack-download/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }

    async function downloadTimesheet(e) {
        setLoading(true)
        localStorage.setItem('clockInTimeForSage', JSON.stringify(clockInTime));
        try {
            let { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/timesheet-download/?companyID=${companyID}&clockInTime=${clockInTime}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with sage!!")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }


    async function refreshToken() {

        setLoading(true)
        try {
            let resp = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/refresh/?companyID=${companyID}`,
                config
            );
            setConnected(true)
            setLoading(false)
        } catch (error) {
            setConnected(false)
            setLoading(false)

        }
    }

    async function disconnect(e) {
        setLoading(true)
        try {
            let resp = await axios.get(
                `https://dev.timetraker.com/backend/api/sage/disconnect/?companyID=${companyID}`,
                config
            );
            setConnected(false)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with sage and try again");
        }
    }



    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    useEffect(() => {
        refreshToken()
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const referValue = params.get('error');

        if (referValue) {
            errorNotify("Something went wrong, Please try agin.")
        }

    }, []);

    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <AdminLayout>
            <ToastContainer />
            {
                loading ?
                    <Loder className='loder' />
                    :
                    console.log("")
            }
            <div
                // style={loading ? { filter: 'blur(2px)' } : console.log('')}
                className={sideBarStatus ? "content_right_dashboard_quick" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : loading ?
                            { filter: 'blur(2px)', display: "block", minHeight: "100vh", background: "#f1f1f1" }
                            : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                }
            >

                <Container style={{ padding: "0" }} fluid className="dash3" >
                    {/* Row 1 */}
                    <Row>
                        <Col className="task-container">
                            <div
                                className="hamburgar"
                                style={
                                    sideBarStatus ? { display: "none" } : { display: "block" }
                                }
                            >
                                <i onClick={handleClick} className="fas fa-bars"></i>
                            </div>
                            <div className="task-top">
                                <div
                                    className="task-header1"

                                >
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Sage
                                    </h5>
                                </div>

                                <div className="profile-header2">

                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div style={{ padding: "15px 30px" }}>
                    <div style={{ marginTop: "41px", marginBottom: "27px" }} className="headline">
                        <h1>Integrate with Sage</h1>
                    </div>

                    <Row style={{ background: "#F1F1F1", }}>
                        <Col md={12}>
                            <div className="adduser peoject_form_padding" style={{ margin: "22px 47px 60px" }}>
                                <div className="adduser-header project-header">
                                    <button style={{ width: "170px" }} className="btn1">Sage Info</button>
                                </div>


                                <form
                                    onSubmit={handleSubmit}
                                    action=""
                                    className="adduser-form"
                                    style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                                >

                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                        <div style={{ display: "flex" }} className="form1 ">
                                            <div className="mb-5 projectInput">
                                                <label className="lable_bold" htmlFor="">
                                                    API KEY
                                                    {!connected ?
                                                        <i style={{ marginLeft: "4px" }} className="fa-sharp fa-light fa-asterisk"></i> : null
                                                    }
                                                </label>
                                                <input
                                                    required={!connected ? true : false}
                                                    value={formData.apiKey}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="apiKey"
                                                    id=""
                                                    style={{ minWidth: "250px" }}
                                                />
                                            </div>

                                            <div style={{ marginLeft: "20px" }} className="mb-5 projectInput">
                                                <label className="lable_bold" htmlFor="">
                                                    Subdomain
                                                    {!connected ?
                                                        <i style={{ marginLeft: "4px" }} className="fa-sharp fa-light fa-asterisk"></i> : null
                                                    }
                                                </label>
                                                <input
                                                    required={!connected ? true : false}
                                                    value={formData.subdomain}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="subdomain"
                                                    id=""
                                                    style={{ minWidth: "200px" }}
                                                />
                                            </div>

                                        </div>

                                        <div className="form2">

                                            <div className="role-border projectInput">
                                                <div className="adduserBtn connect-with-bamboohr">
                                                    {!connected ? <button style={{ marginBottom: "27px", border: "1px solid #64E48E" }} type="submit" className="btn5">
                                                        Connect
                                                    </button> :
                                                        <button style={{ marginBottom: "27px", border: "1px solid #64E48E", background: "white" }} onClick={disconnect} className="btn5">
                                                            Disconnect
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </Col>
                    </Row>





                    <div className="api-button">
                        <h2>Sync and Download required data </h2>
                        <div className="api-button-container">

                            <div className="sync_button">

                                <button
                                    className="btn btn-success btn_qb_2"
                                onClick={getEmployee}
                                >
                                    Sync Employee
                                </button>

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={getCustomer}
                                >
                                    Sync Project
                                </button>

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={getWorkingPack}
                                >
                                    Sync Working Packages
                                </button>
                                {/* 
                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={getCalendar}
                                >
                                    Sync Pay Run Calendars
                                </button>
                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={getPayItems}
                                >
                                    Sync Pay Items
                                </button>
                                */}

                            </div>

                            <div className="download_button">

                                <button
                                    className="btn btn-success btn_qb_2"
                                onClick={downloadEmployee}
                                >
                                    Download Employee
                                </button>


                               <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={downloadProject}
                                >
                                    Download Project
                                </button>

                               <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={downloadWorkingPack}
                                >
                                    Download  Working Packages
                                </button>

                      

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={handleClickOpen}
                                >
                                    Download Timesheets
                                </button>
                
 {/* 
                      
                                    <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={downloadTimesheet}
                                >
                                    Download Time Logs
                                </button>
                                */}



                            </div>

                        </div>


                    </div>
                </div>



                <div className="more-info">
                    <hr />
                    <p>More Info:</p>
                    <ul>
                        <li>
                            <a href="#">
                                Integrate With Sage Guide
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Sync And Download Gide
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                About TimeTraker
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <hr /> */}
                <p className="text-center text-muted">
                    &copy; 2024 TimeTraker&trade;, All rights reserved. TimeTraker and
                    FCIT are registered trademarks of TimeTraker.
                </p>

            </div>

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div
                    className="dialog-main"
                    style={{ height: "297px" }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <DialogActions>
                            <Button
                                style={{

                                    background: "rgb(100, 228, 142)",
                                    borderRadius: "10px",
                                    color: "#000",
                                    fontWeight: "600",
                                    padding: "5px 15px",
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </div>

                    <form onSubmit={downloadTimesheet} className="border-around">
                        <div style={{ display: "flex" }} className="form-time1 ">


                            <div style={{ display: "flex", width: "60%" }} className="form-time1 ">
                                <div style={{ width: "100%", display: "flex", flexDirection: "column" }} className="customer">
                                    <label className="lable_bold" htmlFor="">
                                        Clock-In Time
                                        <i className="fa-sharp fa-light fa-asterisk"></i>
                                    </label>

                                    <input
                                        style={{ height: '40px' }}
                                        required
                                        value={clockInTime}
                                        onChange={(e)=>setClockInTime(e.target.value)}
                                        type="text"
                                        name="clockInTime"
                                        id=""
                                        placeholder="HH:MM"
                                        pattern="^([01]\d|2[0-3]):([0-5]\d)$"
                                    />
                                </div>

                            </div>
                        </div>



                        <div className="strong">
                            <button className="button_strong" type="submit" style={{ display: "flex" }}>
                                Done
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </AdminLayout >
    );
};

export default Sage;
