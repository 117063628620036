

import * as React from 'react';
import { useContext, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import { Dialog } from '@mui/material';
import { Col, Form, Container, Row } from "react-bootstrap";
import "../pages/admin/AddUser.css"
import { useState } from 'react';
import { MyContext } from '../context/MyProvider';
import UserTimeOffDetails from '../pages/admin/UserAdditionalDetails/TimeOffDetails/UserTimeOffDetails';
import UserHolidayDetails from '../pages/admin/UserAdditionalDetails/HolidayDetails/UserHolidayDetails';
import UserAssignedProjectDetails from '../pages/admin/UserAdditionalDetails/AssignProject/UserAssignedProjectDetails';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'phone', label: 'Phone', minWidth: 150 },
    { id: 'role', label: 'Role', minWidth: 150 },
    { id: 'basePayRate', label: 'Cost Rate', minWidth: 150 },
    { id: 'billRate', label: 'Bill Rate', minWidth: 150 },
    { id: 'hireDate', label: 'Hire Date', minWidth: 150 },
    {
        id: 'approver',
        label: 'Approver',
        minWidth: 150,
        align: 'center'
    },
    {
        id: 'edit',
        label: 'Edit',
        minWidth: 80,
        align: 'center'
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 80,
        align: 'center'
    }
];

// temporory data

const data = [
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ allUser, usersAndManagers, handleDataFromChild }) {

    const { activTab, setActivTab, newUser, setNewUser, additionalSettings } = useContext(MyContext);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [next, setNext] = useState(false);
    const [teamInfo, setTeamInfo] = useState([]);
    const [payCalendarInfo, setPayCalendarInfo] = useState([]);
    const [payItemInfo, setPayItemInfo] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
        var userName = adminloginInfo.name;
        var accessibility = adminloginInfo.accessibility;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
        var userName = userLoginInfo.name;
        var accessibility = userLoginInfo.accessibility;
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };


    const getAllTeam = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/all-team/?companyID=${companyID}`,
                config
            );
            setTeamInfo(data);
        } catch (error) {
            console.log("error from team api", error);
        }
    };

    const getPayCalendarAndItem = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/get-pay-calendar-and-item/?companyID=${companyID}`,
                config
            );
            setPayItemInfo(data?.payItem)
            setPayCalendarInfo(data?.payCalendar)
        } catch (error) {
            console.log("error from team api", error);
        }
    };

    const handleSwitchChange = async (e, _id, ItemRole) => {
        try {
            if (ItemRole == 'sub-admin') {
                const { data } = await axios.post(
                    `https://dev.timetraker.com/backend/api/active-sub-admin/?_id=${_id}`,
                    { checked: e.target.checked },
                    config
                );

                if (data) {
                    handleDataFromChild(data);
                    successNotifyForUser("Employee status has been changed!!");
                }
            }
            else if (ItemRole == "manager") {
                const { data } = await axios.post(
                    `https://dev.timetraker.com/backend/api/active-manager/?_id=${_id}`,
                    { checked: e.target.checked },
                    config
                );

                if (data) {
                    handleDataFromChild(data);
                    successNotifyForUser("Employee status has been changed!!");
                }
            } else if (ItemRole == "user") {
                const { data } = await axios.post(
                    `https://dev.timetraker.com/backend/api/active-user/?_id=${_id}`,
                    { checked: e.target.checked },
                    config
                );

                if (data) {
                    handleDataFromChild(data);
                    successNotifyForUser("Employee status has been changed!!");
                }
            }
        } catch (err) {
            console.log(err);
            errorNotify("Something went wrong!!")
        }
    }




    const successNotifyForUser = (text) => {
        toast.success(text, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotify = (text) => {
        toast.error(text, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const [formDataUser, setFromDataUser] = useState({
        companyID: companyID,
    });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (item) => {
        setNewUser(item)
        setFromDataUser({
            companyID: companyID,
            name: item.name,
            email: item.email,
            phone: item.phone,
            password: item.password,
            role: item.role,
            _id: item._id,
            approverName: item?.approver?.name,
            approverID: item?.approver?.id,
            approver: item?.approver?.id,
            hireDate: item?.hireDate,
            basePayRate: item?.basePayRate ? item?.basePayRate : 0,
            billRate: item?.billRate ? item?.billRate : 0,
            payrollCalendarID: item?.xero?.payrollCalendar?.id ? item?.xero?.payrollCalendar?.id : "",
            earningsRateID: item?.xero?.earningsRate?.id ? item?.xero?.earningsRate?.id : ""
        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };





    const handleChangeUser = (e) => {
        const { name, value } = e.target;
        setFromDataUser((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "approver") {
            const data = usersAndManagers.filter((item) => item._id === value)
            setFromDataUser((prevData) => ({
                ...prevData,
                approverName: data?.[0]?.name,
                approverID: data?.[0]?._id,
            }));
        }
    };


    const handleSubmitUser = async (e, goNext) => {
        e.preventDefault();
        setNext(goNext)


        const filterPayCalender = payCalendarInfo.filter((item) => item.payrollCalendarID === formDataUser.payrollCalendarID)
        const filterPayItem = payItemInfo.filter((item) => item.earningsRateID === formDataUser.earningsRateID)
        const earningsRateName = filterPayItem?.[0]?.name ? filterPayItem?.[0]?.name : ""
        const payrollCalendarName = filterPayCalender?.[0]?.name ? filterPayCalender?.[0]?.name : ""



        if (formDataUser.role == "sub-admin") {
            try {
                const { data } = await axios.post(
                    "https://dev.timetraker.com/backend/api/sub-admin-edit",
                    {
                        formDataUser: formDataUser,
                        earningsRateName: earningsRateName,
                        payrollCalendarName: payrollCalendarName
                    },
                    config
                );
                if (data?.message == "email exist") {
                    alert("Email already exist, Please try with another email address")
                }
                else if (data?.message == "name exist") {
                    alert("name already exist, Please chnage the name")
                } else {
                    successNotifyForUser("Employee details has been updated!!")
                    setFromDataUser({
                        companyID: formDataUser.companyID,
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        role: "",
                        hireDate: "",
                        basePayRate: 0,
                        billRate: 0,
                        payrollCalendarID: "",
                        earningsRateID: ""
                    })
                    handleDataFromChild(data);
                    if (!goNext) {
                        handleClose()
                    } else {
                        setActivTab("policy")
                    }
                }
            } catch (error) {
                console.log("add user api", error);
            }
        } else if (formDataUser.role == "admin") {
            try {
                const { data } = await axios.post(
                    "https://dev.timetraker.com/backend/api/admin-edit",
                    {
                        formDataUser: formDataUser,
                        earningsRateName: earningsRateName,
                        payrollCalendarName: payrollCalendarName
                    },
                    config
                );
                if (data?.message == "email exist") {
                    alert("Email already exist, Please try with another email address")
                }
                else if (data?.message == "name exist") {
                    alert("name already exist, Please chnage the name")
                } else {
                    successNotifyForUser("Employee details has been updated!!")
                    setFromDataUser({
                        companyID: formDataUser.companyID,
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        role: "",
                        hireDate: "",
                        basePayRate: 0,
                        billRate: 0,
                        payrollCalendarID: "",
                        earningsRateID: ""
                    })
                    handleDataFromChild(data);
                    if (!goNext) {
                        handleClose()
                    } else {
                        setActivTab("policy")
                    }
                }
            } catch (error) {
                console.log("add user api", error);
            }
        } else if (formDataUser.role == "manager") {
            try {
                const { data } = await axios.post(
                    "https://dev.timetraker.com/backend/api/manager-edit",
                    {
                        formDataUser: formDataUser,
                        earningsRateName: earningsRateName,
                        payrollCalendarName: payrollCalendarName
                    },
                    config
                );
                if (data?.message == "email exist") {
                    alert("Email already exist, Please try with another email address")
                }
                else if (data?.message == "name exist") {
                    alert("name already exist, Please chnage the name")
                } else {
                    successNotifyForUser("Employee details has been updated!!")
                    setFromDataUser({
                        companyID: formDataUser.companyID,
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        role: "",
                        hireDate: "",
                        basePayRate: 0,
                        billRate: 0,
                        payrollCalendarID: "",
                        earningsRateID: ""
                    })
                    handleDataFromChild(data);
                    if (!goNext) {
                        handleClose()
                    } else {
                        setActivTab("policy")
                    }
                }
            } catch (error) {
                console.log("add user api", error);
            }
        } else if (formDataUser.role == "user") {
            try {
                const { data } = await axios.post(
                    "https://dev.timetraker.com/backend/api/user-edit",
                    {
                        formDataUser: formDataUser,
                        earningsRateName: earningsRateName,
                        payrollCalendarName: payrollCalendarName
                    },
                    config
                );
                if (data?.message == "email exist") {
                    alert("Email already exist, Please try with another email address")
                }
                else if (data?.invalidPass) {
                    alert("Password must have at least 1 uppercase, 1 lowercase, and 1 number")
                }
                else if (data?.message == "name exist") {
                    alert("name already exist, Please chnage the name")
                } else {
                    successNotifyForUser("Employee details has been updated!!")
                    setFromDataUser({
                        companyID: formDataUser.companyID,
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        role: "",
                        hireDate: "",
                        basePayRate: 0,
                        billRate: 0,
                        payrollCalendarID: "",
                        earningsRateID: ""
                    })
                    handleDataFromChild(data);
                    if (!goNext) {
                        handleClose()
                    } else {
                        setActivTab("policy")
                    }
                }
            } catch (error) {
                console.log("add user api", error);
            }

        }


    };

    // useEffect(() => {
    //     console.log("newUser1", newUser)
    //     if (next && newUser?._id) {
    //         setActivTab("policy")
    //     }
    // }, [newUser]);

    useEffect(() => {
        getAllTeam()
        getPayCalendarAndItem()
    }, [])

    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" style={{ overflow: 'visible' }}>
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={column.id === "approver" && additionalSettings.expenseApprover === false && additionalSettings.timesheetApprover === false ? { display: "none" } : { minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label}

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead  >
                    <TableBody style={{ overflow: 'hidden' }}>
                        {allUser
                            ? (rowsPerPage > 0 ? allUser.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : allUser)?.map((item, index) => {

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.name}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.email}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.phone}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.role === "sub-admin" ? "admin" : item?.role === "admin" ? "super admin" : item?.role}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.basePayRate ? item?.basePayRate : 0}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.billRate ? item?.billRate : 0}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.hireDate ? item?.hireDate : "Null"}</TableCell>
                                        <TableCell style={additionalSettings.expenseApprover === false && additionalSettings.timesheetApprover === false ? { display: "none" } : { textAlign: 'center' }}>{item?.approver?.name ? item?.approver?.name : "null"}</TableCell>
                                        <TableCell align="center"><i onClick={() => (handleClickOpen(item))}
                                            style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }} class="fa-solid fa-pen-to-square userIconsHover"></i></TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.role === "admin" ? true : item?.status} onChange={(e) => { handleSwitchChange(e, item._id, item.role) }} />}</TableCell>
                                    </TableRow>
                                );
                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow style={{ textAlign: 'center' }} hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.name}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.email}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.phone}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.role}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{item?.createdAt}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={allUser?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
            >

                <Container fluid style={{ padding: "0" }}>
                    <Row style={activTab === "user" ? { background: "#F1F1F1" } : { display: "none" }} >
                        <Col style={{ padding: "0px" }} md={12}>
                            <div
                                style={{
                                    minWidth: "886px",
                                    margin: "0px",
                                    padding: "20px",
                                    borderRadius: "10px"
                                }}
                                className="adduser">
                                <div style={{ paddingBottom: "20px", justifyContent: "space-between" }} className="adduser-header">
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <button className="btn1">User Info</button>
                                        <button onClick={() => setActivTab("policy")} style={{ background: "white" }} className="btn1"> Time Off Info</button>
                                        <button onClick={() => setActivTab("holiday")} style={{ background: "white" }} className="btn1">Holiday Info</button>
                                    </div>
                                    <div>
                                        <button onClick={() => handleClose()} className="btn1">Close</button>
                                    </div>
                                </div>

                                <form
                                    onSubmit={handleSubmitUser}
                                    action=""
                                    className="adduser-form add_user_page_form"
                                    autocomplete="off"
                                >
                                    <div style={{ margin: "0px" }} className="form1">
                                        <div className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Name
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                required
                                                onChange={handleChangeUser}
                                                type="text"
                                                name="name"
                                                id=""
                                                value={formDataUser.name}
                                            />
                                        </div>
                                        <div className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Email
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                autocomplete="off"
                                                required
                                                onChange={handleChangeUser}
                                                type="email"
                                                name="email"
                                                id="amount"
                                                className="amount"
                                                value={formDataUser.email}
                                            />
                                        </div>
                                        <div className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Phone

                                            </label>
                                            <input
                                                onChange={handleChangeUser}
                                                type="number"
                                                name="phone"
                                                id=""
                                                value={formDataUser.phone}
                                            />
                                        </div>

                                        <div className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Hire Date
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                required
                                                onChange={handleChangeUser}

                                                autocomplete="off"
                                                type="date"
                                                name="hireDate"
                                                id=""
                                                value={formDataUser?.hireDate}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}
                                            className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Team
                                            </label>
                                            <Form.Select

                                                onChange={handleChangeUser}
                                                autocomplete="off"
                                                name="team"
                                                className="role"
                                                aria-label="Default select example"
                                                value={formDataUser.team}
                                                style={{ marginLeft: "0px" }}
                                            >
                                                <option value="">Select</option>
                                                {teamInfo?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item?._id}>{item?.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>

                                        <div
                                            style={
                                                accessibility.xero === false ? { display: "none" } :
                                                    {
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between"
                                                    }}
                                            className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Pay Calendar
                                            </label>
                                            <Form.Select

                                                onChange={handleChangeUser}
                                                autocomplete="off"
                                                name="payrollCalendarID"
                                                className="role"
                                                aria-label="Default select example"
                                                value={formDataUser.payrollCalendarID}
                                                style={{ marginLeft: "0px" }}
                                            >
                                                <option value="">Select</option>
                                                {payCalendarInfo?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item?.payrollCalendarID}>{item?.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>

                                    </div>

                                    <div style={{ margin: "0px" }} className="form2">
                                        <div className="add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Password
                                            </label>
                                            <input

                                                autocomplete="off"
                                                onChange={handleChangeUser}
                                                type="password"
                                                name="password"
                                                id=""
                                                value={formDataUser.password}
                                            />
                                        </div>
                                        <div className="role-border add_user_input_margin_bottom_for_dialog">
                                            <label className="lable_bold" htmlFor="">
                                                Role
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <Form.Select
                                                required
                                                disabled
                                                onChange={handleChangeUser}
                                                name="role"
                                                className="role"
                                                aria-label="Default select example"
                                                value={formDataUser.role}
                                            >
                                                <option value="">Select</option>
                                                <option value="sub-admin">Admin</option>
                                                <option value="manager">Manager</option>
                                                <option value="user">User</option>
                                            </Form.Select>


                                        </div>

                                        <div title="Cost Rate / Hours" className="add_user_input_margin_bottom_for_dialog company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Cost Rate ($)
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                required
                                                onChange={handleChangeUser}

                                                autocomplete="off"
                                                type="number"
                                                name="basePayRate"
                                                id=""
                                                value={formDataUser?.basePayRate}
                                            />
                                        </div>

                                        <div title="Cost Rate / Hours" className="add_user_input_margin_bottom_for_dialog company_settings_input">
                                            <label className="lable_bold" htmlFor="">
                                                Bill Rate ($)
                                            </label>
                                            <input

                                                onChange={handleChangeUser}

                                                autocomplete="off"
                                                type="number"
                                                name="billRate"
                                                id=""
                                                value={formDataUser?.billRate}
                                            />
                                        </div>


                                        <div style={additionalSettings.expenseApprover === false && additionalSettings.timesheetApprover === false ? { display: "none" } : null} className="role-border mb-4">
                                            <label className="lable_bold" htmlFor="">
                                                Approver
                                            </label>
                                            <Form.Select

                                                onChange={handleChangeUser}
                                                name="approver"
                                                style={{ margin: "0px" }}
                                                className="role"
                                                aria-label="Default select example"
                                                value={formDataUser.approver}
                                                disabled={formDataUser.role === "sub-admin" || formDataUser.role === "admin" ? true : false}
                                            >
                                                <option value="">Select</option>
                                                {usersAndManagers?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id}>{item?.name}</option>
                                                    )
                                                })}

                                            </Form.Select>


                                        </div>

                                        <div style={accessibility.xero === false ? { display: "none" } : {marginTop: "32px"}} className="role-border mb-4">
                                            <label className="lable_bold" htmlFor="">
                                                Pay Item
                                            </label>


                                            <Form.Select

                                                onChange={handleChangeUser}
                                                name="earningsRateID"
                                                style={{ margin: "0px" }}
                                                className="role"
                                                aria-label="Default select example"
                                                value={formDataUser.earningsRateID}
                                            >
                                                <option value="">Select</option>
                                                {payItemInfo?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.earningsRateID}>{item?.name}</option>
                                                    )
                                                })}

                                            </Form.Select>


                                        </div>



                                    </div>
                                </form>
                                <div className="adduserBtn ">

                                    <button style={{ marginBottom: "22px" }} onClick={(e) => handleSubmitUser(e, false)} className="btn5">
                                        Save
                                    </button>
                                    <button style={{ marginBottom: "22px" }} onClick={(e) => handleSubmitUser(e, true)} className="btn5">
                                        Save & Next
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div style={activTab === "policy" ? { display: "block" } : { display: "none" }}>
                        {newUser && <UserTimeOffDetails editTab={true} newUser={newUser} handleClose={handleClose} />}
                    </div>

                    <div style={activTab === "holiday" ? { display: "block" } : { display: "none" }}>
                        {newUser && <UserHolidayDetails editTab={true} newUser={newUser} handleClose={handleClose} />}
                    </div>


                </Container>
            </Dialog>
        </Paper>
    );
}