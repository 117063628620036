import React, { useState, useEffect, useContext } from "react";
import "./Task.css";
import { Col, Container, Row, Form } from "react-bootstrap";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import ChartOfAccountTable from "../../components/ChartOfAccountTable"
import { MyContext } from "../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileComponent from "../../components/ProfileComponent";
import { useNavigate } from "react-router-dom";

const ChartOfAccount = () => {
    const navigate = useNavigate()
    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }

    const [chartOfAccountInfo, setChartOfAccountInfo] = useState([]);
    const [newData, setNewData] = useState();

    // config

    const config = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };



    const getAllChartOfAccounts = async () => {
        try {
            const { data } = await axios.get(
                `https://dev.timetraker.com/backend/api/get-chart-of-account-for-admin/?companyID=${companyID}`,
                config
            );
            setChartOfAccountInfo(data.chartOfAccount);
        } catch (error) {
            console.log("error from get Project api", error);
        }
    };


    const handleDataFromChild = (deletedProject) => {
        setNewData(deletedProject);
    };

    useEffect(() => {
        getAllChartOfAccounts();
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    useEffect(() => {
        getAllChartOfAccounts();
    }, [newData]);




    return (
        <AdminLayout>
            <ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard_2" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                }
            >
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col className="task-container">
                            <div
                                className="hamburgar"
                                style={
                                    sideBarStatus ? { display: "none" } : { display: "block" }
                                }
                            >
                                <i onClick={handleClick} className="fas fa-bars"></i>
                            </div>
                            <div className="task-top">
                                <div className="task-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Expense Settings
                                    </h5>
                                </div>

                                <div className="profile-header2">

                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ background: "#F1F1F1" }} className="mt-0">
                        <Col>
                            <div
                                className="time"
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginLeft: "84px"
                                }}
                            >

                                <div className="text-time">

                                    <p
                                        style={{ backgroundColor: "#64E48E", cursor: "pointer", marginBottom: "0px", width: "190px" }}
                                        className="para1"

                                    >
                                        Chart Of Accounts
                                    </p>

                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px", width: "160px"  }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/expense-settings");
                                        }}
                                    >
                                        Expense Settings
                                    </p>

                                </div>


                            </div>
                        </Col>
                    </Row>



                    <Row style={{ background: "#F1F1F1", marginTop: "20px" }}>
                        <Col>
                            <div className="table-container">
                                <ChartOfAccountTable
                                    chartOfAccountInfo={chartOfAccountInfo}
                                    handleDataFromChild={handleDataFromChild}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </AdminLayout>
    );
};

export default ChartOfAccount;
