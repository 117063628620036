import React, { useState, useContext, useEffect } from "react";
import "../../ramComponent/FormRideSide.css";
import { Container, Col, Row } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MyContext } from "../../context/MyProvider";
import timerTrakerlogo from "../../img/TimeTrakerLogo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import signInWithIntuiteDefault from '../../img/Sign_in_transparent_btn_tall_default.svg'
import signInWithIntuiteHover from '../../img/Sign_in_blue_btn_tall_hover.svg'
import ReCAPTCHA from 'react-google-recaptcha';

const FormRideSide = () => {

  const SITE_KEY = '6LcVih4qAAAAAPcT888jTDJiSMmsSuM57S_SBrFj';

  const { setLoading, setSendPass, setShowDemoAlert } = useContext(MyContext);

  const [hoverImg, setHoverImg] = useState(false)

  const [form1, setForm1] = useState({ display: "block" });

  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [refer, setRefer] = useState('');




  // config
  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  };


  const [formData, setFromData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referValue = params.get('refer') || 'empty';
    setRefer(referValue);
    setFromData((prevData) => ({
      ...prevData,
      refer: referValue
    }));
  }, []);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setFromData((prevData) => ({
      ...prevData,
      recaptchaValue: value
    }));
  };


  const handleChange = (e) => {

    const { name, value } = e.target;
    setFromData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  function isValidEmail(emailvalidation) {
    return /\S+@\S+\.\S+/.test(emailvalidation);
  }

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };


  const handleSubmit = async (e) => {
    isValidEmail(formData.email);
    if (!isValidEmail(formData.email)) {
      alert("please enter a valid email address");
    } else {
      validatePhoneNumber(formData.phone);
      if (!validatePhoneNumber(formData.phone)) {
        alert("please enter a valid phone number");
      } else {
        if (formData.c_password != formData.password) {
          alert("Password Confirm Password is not matched");
        } else {
          e.preventDefault();
          if (!recaptchaValue) {
            alert('Please complete the reCAPTCHA.');
            return;
          }

          try {

            const { data } = await axios.post(
              `https://dev.timetraker.com/backend/api/admin-varifyEmail`,
              formData,
              config
            );
            if (data?.message == "email exist") {
              alert("Email already exist, Please try with another email address")
            }
            else if (data?.message == "name exist") {
              alert("name already exist, Please chnage the name")
            } else {
              setShowDemoAlert(true)
              const currentDate = new Date();
              data.admin.logTime = currentDate
              setLoading(false)
              localStorage.setItem('adminLoginInfo', JSON.stringify(data.admin));
              const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
              if (adminLoginInfo?.accessibility?.dashboard === true) {
                navigate('/set-up-account')
              }
            }
          } catch (error) {
            console.log("error from Signup api", error);
            alert("Something went wrong please try again");
          }
        }
      }
    }
  };






  const authorizeUriFn = () => {
    window.location.href = `https://dev.timetraker.com/backend/api/xero/authUri/?companyID=create`;
  }

  async function loginUserWithXero(companyID, token) {

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      },
    };

    setLoading(true)
    try {
      let { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/xero/login/?_id=${companyID}`,
        config
      );
      setLoading(false)

      if (data.admin) {
        setShowDemoAlert(data.admin.firstLogin)
        const currentDate = new Date();
        data.admin.logTime = currentDate
        setLoading(false)
        localStorage.setItem('adminLoginInfo', JSON.stringify(data.admin));
        const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
        if (adminLoginInfo?.accessibility?.dashboard === true) {
          if (data.admin.firstLogin === true) {
            navigate('/set-up-account')
          } else {
            navigate('/admin-dashboard-v2')
          }
        }
      }

    } catch (error) {
      setLoading(false)

    }
  }


  const signInWithIntuite = (e) => {
    e.preventDefault();
    authorizeUriFn();
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const companyID = params.get('companyID');
    const token = params.get('token');

    if (companyID && token) {
      loginUserWithXero(companyID, token)
    }
  }, [])

  return (
    <div style={{ height: "100%" }}>
      {/* Right column */}
      <ToastContainer />
      <div className="header-right" style={form1}>
        <div className="head-text">
          {/* <h1>Logo</h1> */}
          <img className="timeTrakerLogo" src={timerTrakerlogo} alt="" />
          <p>Simplify your time and expense management today.</p>
          <span>No credit card required. Cancel anytime.</span>
        </div>
        <Form onSubmit={handleSubmit}>
          <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Form.Group as={Col} controlId="formGroupEmail">
              {/* <Form.Label>Fist Name<i className="fa-sharp fa-light fa-asterisk"></i> </Form.Label> */}
              <Form.Control
                required
                onChange={handleChange}
                name="name"
                type="text"
                placeholder="Your Name "
                className="singup-input"
              />
            </Form.Group>

            {/* <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Last Name<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                            <Form.Control required onChange={handleChange} name="lastName" type="text" />
                        </Form.Group> */}
          </Row>

          <Row>
            <Form.Group style={{ marginBottom: "15px" }} controlId="formGroupEmail">
              {/* <Form.Label>Email<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label> */}
              <Form.Control
                required
                onChange={handleChange}
                name="email"
                type="email"
                placeholder="Your Email"
                className="singup-input"
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "15px" }} controlId="formGroupEmail">
              {/* <Form.Label>Company Name<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label> */}
              <Form.Control
                required
                onChange={handleChange}
                name="companyName"
                type="text"
                placeholder="Company Name"
                className="singup-input"
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: "15px" }} controlId="formGroupEmail">
              {/* <Form.Label>Phone Number<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label> */}
              <Form.Control
                required
                onChange={handleChange}
                name="phone"
                type="text"
                placeholder="Phone Number"
                className="singup-input"
              />
            </Form.Group>
          </Row>

          <Row >

            <div className="d-grid headBtn mt-1">

              <div className="recap_parent">
                <div className="recap">
                  <ReCAPTCHA
                    sitekey={SITE_KEY}
                    onChange={handleRecaptchaChange}
                    className="custom-recaptcha"
                  />
                </div>

                <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                  <span className='sign_up_with_xero'  onClick={signInWithIntuite} data-xero-sso data-href="/sign-in/xero" data-label="Sign In with Xero">Sign up with Xero</span>
                </div>
              </div>
              <Button type='submit' className='btn'>Start Your Free Trial</Button>
            </div>
          </Row>
        </Form>

        <div className="head-bottom-text">
          <p className="text">
            Already have an account ?{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/xero/login")}
            >
              Login here

            </span>{" "}
          </p>
          <p>
            <span style={{ cursor: 'pointer' }} onClick={() => { navigate("/need-assistance") }} >Need Assistance?</span>
          </p>
        </div>
      </div>

    </div>
  );
};

export default FormRideSide;
