import React, { useEffect, useState, useRef } from 'react'
import signupImg1 from "../../img/Vector.png";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import axios from 'axios';
import "./ChatAfterLogin.css"

const ChatAfterLogin = () => {

        
    const divRef = useRef(null);

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var userID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userName = adminloginInfo.name;
    }

    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var userID = userLoginInfo?._id;
        var token = userLoginInfo?.token
        var userName = userLoginInfo.name;
    }
    //config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("")
    const [newMessage, setNewMessage] = useState()
    const [allMessages, setAllMessages] = useState()


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setMessage(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (message !== "") {
            try {
                const { data } = await axios.post("https://dev.timetraker.com/backend/api/create-suport-message", {
                    companyID: companyID,
                    userID: userID,
                    userName: userName,
                    message: message
                },
                    config)

                if (data) {
                    setNewMessage(data?.createdNewSuport)
                    setMessage("")
                }
            } catch (error) {
                console.log("error", error)
            }
        } else {
            alert("Please write some content!!")
        }
    }

    const getAllMessages = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/get-user-suport-messages/?userID=${userID}`, config)
            setAllMessages(data.allMessages)
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getAllMessages()
    }, [newMessage])

    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
          }
    }, [allMessages]);
    return (
        <div>
            <abbr onClick={handleClickOpen} title="?"><img src={signupImg1} alt="" /></abbr>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Share Your Problems Here!!"}
                </DialogTitle>
                <DialogContent ref={divRef} className='chat_container'>
                    <div className='chat_box'>
                        {
                            allMessages?.messages?.map((item, index) => {
                                return (
                                    <div style={item?.status === "send" ? { display: "flex", justifyContent: "start" } : { display: "flex", justifyContent: "end" }} key={index}>
                                        <p style={item?.status === "send" ? { textAlign: "left" } : { textAlign: "right", background: "#d1e1ff" }} >{item?.content}</p>
                                    </div>
                                )
                            })
                        }
                        <form>
                            <input value={message} onChange={handleChange} placeholder='Write your message hare!!' />
                            <div>
                                <button className='send' onClick={handleSubmit} >
                                    send
                                </button>
                                <button className='close' onClick={handleClose}>
                                    close
                                </button>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ChatAfterLogin
