import React, { useState, useContext, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import '../Prakashcomponent/LoginForm.css'
import { MyContext } from '../../context/MyProvider'
import timerTrakerlogo from "../../img/TimeTrakerLogo.png"
import signInWithIntuiteDefault from '../../img/Sign_in_transparent_btn_tall_default.svg'
import signInWithIntuiteHover from '../../img/Sign_in_blue_btn_tall_hover.svg'


const LoginForm = () => {


    const [hoverImg, setHoverImg] = useState(false)



    const { setLoading, setSendWelcome, setShowDemoAlert } = useContext(MyContext);

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    }

    const [formData, setFromData] = useState({});

    const navigate = useNavigate()



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };




    const login = async (formData) => {
        try {
            setLoading(true)
            const { data } = await axios.post(`https://dev.timetraker.com/backend/api/user-login`, formData, config)
            if (data.admin) {
                setShowDemoAlert(data.admin.firstLogin)
                const currentDate = new Date();
                data.admin.logTime = currentDate
                setLoading(false)
                localStorage.setItem('adminLoginInfo', JSON.stringify(data.admin));
                const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
                if (adminLoginInfo?.accessibility?.dashboard === true) {
                    if (data.admin.firstLogin === true) {
                        navigate('/set-up-account')
                    } else {
                        navigate('/admin-dashboard-v2')
                    }
                }
            } else {
                const currentDate = new Date();
                data.user.logTime = currentDate
                setLoading(false)
                localStorage.setItem('userLoginInfo', JSON.stringify(data.user));
                const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
                if (userLoginInfo) {
                    if(data.user.accessibility.myTimesheets === true){
                    navigate('/my-time')
                    }else if(data.user.accessibility.myExpenses === true){
                        navigate('/my-expense')
                    }else{
                        navigate('/profile')
                    }
                }
            }
        }
        catch (error) {
            setLoading(false)
            if (error?.response?.data?.result == "Lock") {
                alert('This account has been locked for next 30 minutes')
            } else {
                console.log("error from user login", error)
                alert('invalid email and password')
            }
        }
    }






    const handleSubmit = async (e) => {
        e.preventDefault();
        login(formData)

    }



    const authorizeUriFn = () => {
        window.location.href = `https://dev.timetraker.com/backend/api/xero/authUri/?companyID=create`;
    }

    async function loginUserWithXero(companyID, token) {

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };

        setLoading(true)
        try {
            let {data} = await axios.get(
                `https://dev.timetraker.com/backend/api/xero/login/?_id=${companyID}`,
                config
            );
            setLoading(false)
            
            if (data.admin) {
                setShowDemoAlert(data.admin.firstLogin)
                const currentDate = new Date();
                data.admin.logTime = currentDate
                setLoading(false)
                localStorage.setItem('adminLoginInfo', JSON.stringify(data.admin));
                const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
                if (adminLoginInfo?.accessibility?.dashboard === true) {
                    if (data.admin.firstLogin === true) {
                        navigate('/set-up-account')
                    } else {
                        navigate('/admin-dashboard-v2')
                    }
                }
            }
        
        } catch (error) {
            setLoading(false)

        }
    }


    const signInWithIntuite = (e) => {
        e.preventDefault();
        authorizeUriFn();
    }

   useEffect(()=>{
    const params = new URLSearchParams(window.location.search);
    const companyID = params.get('companyID');
    const token = params.get('token');

    if (companyID && token) {
        loginUserWithXero(companyID, token)
    }
   },[])


    const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
    const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
    //   console.log("adminloginInfo==========>", adminloginInfo)
    if (adminloginInfo) {
        var logTime = adminloginInfo.logTime
    }
    else if (userLoginInfo) {
        var logTime = userLoginInfo.logTime
    }

    useEffect(() => {
        const currentDate = JSON.parse(JSON.stringify(new Date()));


        const time1 = new Date(currentDate);
        const time2 = new Date(logTime);
        const timeDifferenceInMillis = Math.abs(time1 - time2);
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInMillis / (1000 * 60)); // converting milliseconds to minutes



        if (timeDifferenceInMinutes > 29) {
            localStorage.removeItem("adminLoginInfo");
            localStorage.removeItem("userLoginInfo");
            window.location.reload();
            navigate("/xero/login")
        } else {
            const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
            const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
            setSendWelcome(true)
            if (adminloginInfo) {
                navigate('/admin-dashboard-v2')
            }

            if (userLoginInfo) {
                navigate('/my-time')
            }
        }
    }, [])

    // useEffect(() => {
    //     const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    //     const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    //     if (adminloginInfo) {
    //         navigate('/admin-dashboard-v2')
    //     }

    //     if (userLoginInfo) {
    //         navigate('/my-time')
    //     }
    // }, [])

    return (
        <div className='login_form_main_parent' >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {/* <h3 className='logo_right_log'>Logo</h3> */}
                <img style={{ width: '70px' }} src={timerTrakerlogo} alt='' />

                <p className='right_heading_log '>Simplify your time and expense management today.</p>
                {/* <p className='right_heading_log2'>No credit card required. Cancel anytime.</p> */}
            </div>
            <div className='form_parent_log'>
                <Form onSubmit={handleSubmit} className='mt-4' >
                    <Row>
                        <Form.Group className="mb-1 form-group-email" controlId="formGroupEmail">
                            <Form.Label>Email<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                            <Form.Control onChange={handleChange} name="email" type="email" />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group className='form-group-email' as={Col} controlId="formGroupEmail">
                            <Form.Label>Password<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                            <Form.Control onChange={handleChange} name="password" type="password" />
                        </Form.Group>
                    </Row>

                    <div className="d-grid headBtn1">
                        <Button type='submit'>Login</Button>
                        <Button style={{ border: '1px solis #64E48E', marginTop: "10px" }} onClick={() => { navigate('/forgot-password') }} className='btnhover'>Forgot Password?</Button>
                        {/* <Button style={{ border: '1px solis #64E48E' }} onClick={signInWithIntuite} className=' btnhover btnhover-intuite'>Sign in With Intuite</Button> */}
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <span className='sign_in_with_xero' onClick={signInWithIntuite} data-xero-sso data-href="/sign-in/xero" data-label="Sign In with Xero">Sign In with Xero</span>
                        </div> 
                    </div>
                    <div className='head-bottom-text1'>
                        <p className='text  mt-1' style={{ fontSize: '15px', marginBottom: "0px" }} >Already have an account ?  <span style={{ cursor: 'pointer', fontFamily: 'Inter', fontSize: '15px' }} onClick={() => { navigate('/xero/Signup') }}>Sign up here</span> </p>

                    </div>

                </Form>
            </div>
            <div>
                <p onClick={() => { navigate("/need-assistance") }} className='need_ass' style={{ fontSize: "16px", fontFamily: 'Inter', marginTop: "0px" }}> <span style={{ color: "#2385DF", cursor: 'pointer', fontFamily: 'Inter' }}> Need Assistance ?</span></p>
            </div>


        </div>
    )
}

export default LoginForm
