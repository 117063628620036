import React, { useEffect, useState, useContext } from 'react'
import Excel from './Excel'
import Filter from './Filter'
import Pdf from './Pdf'
import Csv from './Csv'
import axios from 'axios'
import Form from "react-bootstrap/Form";
import './TimesheetReport.css'
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from '../../context/MyProvider'
import { Col, Container, Row } from "react-bootstrap";
import signupImg1 from "../../img/Vector.png"
import signupImg2 from "../../img/user2.png"
import { Dialog, Grid } from '@mui/material'
import ReportTimesheetTable from '../../components/ReportTimesheetTable'
import { useNavigate } from 'react-router-dom'
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const TimesheetReport = () => {


  const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false)
    } else {
      setSideBarStatus(true)
    }
  }


  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true
  } else {
    var forPhoneScreenNoDisplay = false
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };



  const navigate = useNavigate()



  const [allUserTimesheetInfo, setAllUserTimesheetInfo] = useState()

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));


  if (adminloginInfo) {
    var companyID = adminloginInfo._id
    var token = adminloginInfo?.token
  }
  else if (userLoginInfo) {
    var companyID = userLoginInfo.companyID
    var token = adminloginInfo?.token
  }

  // config 

  const config = {
    headers: {
      "Accept": "application/json",
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  const [allUser, setAllUser] = useState([]);
  const [allManager, setAllManager] = useState([]);
  const [allSubAdmin, setAllSubAdmin] = useState([]);
  const [allSuperAdmin, setAllSuperAdmin] = useState([]);
  const [companyName, setCompanyName] = useState('');

  const { reportFilteredData, selectedPropertiesForTimesheet, setReportFilteredData } = useContext(MyContext);



  // get all user Timesheet

  const getAllUserTimesheet = async () => {
    try {
      const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-timesheet-report/?companyID=${companyID}`, config)
      setAllUserTimesheetInfo(data.timesheets)
      setReportFilteredData(data.timesheets)
    } catch (error) {
      console.log(error)
    }
  }

  // let allTheEmployee = []

  const getAllUser = async () => {
    try {
      const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-users/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allUsers)

      setAllUser(data.allUsers)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }


  const getAllManager = async () => {
    try {
      const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-manager/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allManager)
      setAllManager(data.allManager)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }

  const getAllSubAdmin = async () => {
    try {
      const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allSubAdmin)
      setAllSubAdmin(data.allSubAdmin)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }


  const getSuperAdmin = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
        config
      );
      setAllSuperAdmin(data.superAdmin)
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };


  const getCompanyName = async () => {
    try {
      const { data } = await axios.get(`https://dev.timetraker.com/backend/api/company-name/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allSubAdmin)
      console.log("company", data)
      setCompanyName(data.company.companyName)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }




  // filter

  const [projectInfo, setProjectInfo] = useState([]);
  const [taskInfo, setTaskInfo] = useState([]);

  // get all project

  const getAllProject = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-project-for-admin/?companyID=${companyID}`,
        config
      );
      setProjectInfo(data.projects);
    } catch (error) {
      console.log('error', error)
    }
  };

  // get all task

  const getAllTask = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-task-for-admin/?companyID=${companyID}`,
        config
      );
      setTaskInfo(data.tasks);
    } catch (error) {
      console.log('error', error)
    }
  };


  const [filterFormData, setFilterFormData] = useState({
    companyID: companyID
  })

  const [downloadFilter, setDownloadFilter] = useState('')


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setReportFilteredData([])
    handleClickOpen()
    try {
      const { data } = await axios.post(`https://dev.timetraker.com/backend/api/filtered-timesheet-report/?companyID=${companyID}`, filterFormData, config)
      if (downloadFilter === '') {
        const filterData = (data, selectedProperties) => {
          const keysToKeep = Object.keys(selectedProperties).filter(key => selectedProperties[key]);
          
          return data.map(item => {
            const filteredItem = {};
            keysToKeep.forEach(key => {
              if (item.hasOwnProperty(key)) {
                filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
        };
  
        setReportFilteredData(filterData(data.timesheets, selectedPropertiesForTimesheet));
      } else{
        const filteredData = data.timesheets.filter((item) => item.Status === downloadFilter)

        const filterData = (data, selectedProperties) => {
          const keysToKeep = Object.keys(selectedProperties).filter(key => selectedProperties[key]);
          
          return data.map(item => {
            const filteredItem = {};
            keysToKeep.forEach(key => {
              if (item.hasOwnProperty(key)) {
                filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
        };
  
        setReportFilteredData(filterData(filteredData, selectedPropertiesForTimesheet));
      }



    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    getAllUserTimesheet()
    getAllTask()
    getAllProject()
    getAllSubAdmin()
    getAllManager()
    getAllUser()
    getSuperAdmin()
    getCompanyName()
    if (!forPhoneScreenNoDisplay) {
      setSideBarStatus(false)
    }
  }, [])



  const propertyLabels = {
    Date: "Date",
    Task: "Task",
    Project: "Project",
    Hours: "Hours",
    Status: "Status",
    Employee: "Employee",
    Billable: "Billable",
    Description: "Description",
    Location: "Location",
  };

  const columnData = Object.entries(selectedPropertiesForTimesheet)
    .filter(([key, value]) => value)
    .map(([key]) => ({
      id: key,
      label: propertyLabels[key] || key,
      minWidth: key === "Date" ? 130 : 100,
    }));

  return (
    <AdminLayout>
      <div className={sideBarStatus ? 'content_right_dashboard' : "none"} fluid style={sideBarStatus == true & forPhoneScreenNoDisplay == false ? { display: 'none' } : { display: 'block' }}>
        <Container fluid className="dash3" style={{ padding: "0", minHeight: '100vh' }}>
          {/* Row 1 */}
          <Row  >
            <Col className='task-container' >
              <div className="hamburgar" style={sideBarStatus ? { display: 'none' } : { display: 'block' }}>
                <i onClick={handleClick} className='fas fa-bars'></i>
              </div>
              <div className="task-top">
                <div className="task-header1">
                  <h5 style={
                    sideBarStatus == true
                      ? { paddingLeft: "10px", paddingTop: "2px" }
                      : { paddingTop: "4px" }
                  } >Timesheet Reports</h5>
                </div>

                <div className="profile-header2">
                  <ProfileComponent />
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ padding: '20px' }}>
            <Grid container>
              <Grid className='left_grid' item xs={12} lg={5}>
                <div>
                  <p className='left_box_heading'>Select Columns</p>
                  <Filter allUserTimesheetInfo={allUserTimesheetInfo} />
                </div>

              </Grid>
              <Grid className='right_grid' item xs={12} lg={6.5}>

                <form style={{ height: '100%' }} onSubmit={handleSubmit}>
                  <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} >
                    <div>
                      <p className='left_box_heading'>Timesheets Summary</p>



                      <Grid container>
                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <div className='date_duration'>
                            <label className='lable_bold' htmlFor="">Start Date</label>
                            <input style={{ cursor: 'pointer' }} className='date' name='startDate' onChange={handleChange} type='date' />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <div className='date_duration'>
                            <label className='lable_bold' htmlFor="">End Date</label>
                            <input style={{ cursor: 'pointer' }} className='date' name='endDate' onChange={handleChange} type='date' />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <label style={{ cursor: 'pointer' }} className='lable_bold' htmlFor="">Employee</label>
                          <Form.Select
                            style={{ cursor: 'pointer' }}
                            className="area"
                            name="userName"
                            aria-label="Default select example"
                            onChange={handleChange}
                          >
                            <option value=''>
                              Select
                            </option>
                            {allSubAdmin &&
                              allSubAdmin.map((item, index) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            {allManager &&
                              allManager.map((item, index) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            {allUser &&
                              allUser.map((item, index) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            {allSuperAdmin &&
                              allSuperAdmin.map((item, index) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Grid>


                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <label className='lable_bold' htmlFor="">Project</label>
                          <Form.Select
                            style={{ cursor: 'pointer' }}
                            className="area"
                            name="project"
                            aria-label="Default select example"
                            onChange={handleChange}
                          >
                            <option value=''>
                              Select
                            </option>
                            {projectInfo &&
                              projectInfo.map((item, index) => {
                                return (
                                  <option key={index} value={item.project}>
                                    {item.project}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Grid>


                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <label className='lable_bold' htmlFor="">Task</label>
                          <Form.Select
                            style={{ cursor: 'pointer' }}
                            className="area"
                            name="task"
                            aria-label="Default select example"
                            onChange={handleChange}
                          >
                            <option value=''>
                              Select
                            </option>
                            {taskInfo &&
                              taskInfo.map((item, index) => {
                                return (
                                  <option key={index} value={item.task}>
                                    {item.task}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <label className='lable_bold' htmlFor="">Billable</label>
                          <Form.Select
                            style={{ cursor: 'pointer' }}
                            className="area"
                            name="billable"
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option value=''>
                              Select
                            </option>
                            <option value={true} >
                              Yes
                            </option>
                            <option value={false} >
                              No
                            </option>
                          </Form.Select>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6} className='right_selact'>
                          <label className='lable_bold' htmlFor=""> Status</label>
                          <Form.Select
                            style={{ cursor: 'pointer' }}
                            className="area"
                            name="Downloded"
                            aria-label="Default select example"
                            onChange={(e) => { setDownloadFilter(e.target.value) }}
                          >
                            <option value=''>
                              Select
                            </option>
                            <option value="hold" >
                              On Hold
                            </option>
                            <option value="submited" >
                              Submitted
                            </option>
                            <option value="pending download" >
                              Pending Download
                            </option>
                            <option value="downloaded" >
                              Downloaded
                            </option>
                          </Form.Select>
                        </Grid>
                      </Grid>
                    </div>
                    <div className='right_button'>
                      <button style={{ padding: "0px 40px" }} className="csv_button">Filter</button>
                    </div>
                  </div>

                </form>
              </Grid>
            </Grid>


            {/* <ReportTimesheetTable newArray={newArray} /> */}

            <Dialog
              open={open}
              TransitionComponent={Transition}
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              maxWidth="lg"

            >
              <div style={{ padding: "0px 20px 20px 20px" }}>
                <div style={{ margin: '30px 0px' }} className='left_box_buttons'>
                  <Excel companyName={companyName} />
                  <Pdf companyName={companyName} />
                  <Csv companyName={companyName} />
                </div>
                <Paper sx={{ width: "100%", overflow: "auto" }}>
                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      style={{ overflow: "visible", cursor: "pointer" }}
                    >
                      <TableHead>
                        <TableRow>
                          {columnData.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                background: "#04542C",
                                color: "#fff",
                                textAlign: "center",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody
                        style={{ overflow: "hidden", cursor: "pointer" }}
                      >
                        {reportFilteredData?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>

                              {columnData.map((column) => (
                                <TableCell key={column.id} style={{ textAlign: 'center' }}>
                                  {column.id === 'Date' && item.Date}
                                  {column.id === 'Task' && item.Task}
                                  {column.id === 'Project' && item.Project}
                                  {column.id === 'Hours' && item.Hours}
                                  {column.id === 'Status' && item.Status}
                                  {column.id === 'Employee' && item.Employee}
                                  {column.id === 'Billable' && (item.Billable ? "Yes" : "No")}
                                  {column.id === 'Downloaded' && (item.Downloaded === true ? "Yes" : "No")}
                                  {column.id === 'Description' && (item.Description ? item.Description : "Empty")}
                                  {column.id === 'Location' && (item.Location ? item.Location : "Empty")}
                                </TableCell>
                              ))}


                            </TableRow>
                          );
                        })}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </Dialog>

          </div>


        </Container>
      </div>
    </AdminLayout>
  )
}

export default TimesheetReport
