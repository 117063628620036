

import React, { useEffect, useState, useContext } from 'react'
import "./AdminDashboardV2.css"
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import signupImg1 from "../../img/Vector.png"
import signupImg2 from "../../img/user2.png"
import cardImg1 from "../../img/user2-1.png"
import cardImg2 from "../../img/8134653_briefcase_case_office_project_work_icon 1.svg"
import cardImg3 from "../../img/Group 18.png"
import cardImg4 from "../../img/11019415_wallet_accounting_expense_income_dollar_icon 1.svg"
import timesheet1 from "../../img/Group-5.png"
import timesheet2 from "../../img/Group-6.png"
import AdminLayout from '../../hoc/AdminLayout'
import axios from 'axios'
import AdminChart from '../../components/AdminChart'
import { MyContext } from '../../context/MyProvider'
import Form from "react-bootstrap/Form";
import { PaiChartForReport } from '../../components/PaiChartForReport'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Swal from 'sweetalert2'
import Loder from '../../components/Loder'


const AdminDashboardV2 = () => {


    const { sideBarStatus, setSideBarStatus, sendWelcome, setSendWelcome } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false)
        } else {
            setSideBarStatus(true)
        }
    }
    const navigate = useNavigate()


    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true
    } else {
        var forPhoneScreenNoDisplay = false
    }






    const [projectInfo, setProjectInfo] = useState([])
    const [taskInfo, setTaskInfo] = useState([])
    const [allUser, setAllUser] = useState([]);
    const [expenseInfo, setExpenseInfo] = useState([]);
    const [allUserTimesheetInfo, setAllUserTimesheetInfo] = useState([]);
    const [allManager, setAllManager] = useState([]);
    const [allSubAdmin, setAllSubAdmin] = useState([]);
    const [newData, setNewData] = useState("")
    const [showFilterForChart, setShowFilterForChart] = useState(false);

    const [loading, setLoading] = useState(false)

    //ends here 

    const [dateForFilter, setDateForFilter] = useState({ companyID: companyID })
    const [chartFilter, setChartFilter] = useState(false)



    const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
    const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));


    if (adminloginInfo) {
        var companyID = adminloginInfo._id
        var token = adminloginInfo?.token
        var name = adminloginInfo?.name
    }
    else if (userLoginInfo) {
        var companyID = userLoginInfo.companyID
        var token = userLoginInfo?.token
        var name = userLoginInfo?.name
    }

    console.log("projectInfo===>", projectInfo)
    // config 

    const config = {
        headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }



    const TimesheetSubmitNotfy = () => {
        toast.success(`Welcome ${name}`, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };



    const getAllUser = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-users/?companyID=${companyID}`, config)
            setAllUser(data.allUsers)
        } catch (error) {
            console.log('error', error)
        }
    }

    // get all project 

    const getAllProject = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-project/?companyID=${companyID}`, config)
            setProjectInfo(data.projects)
        } catch (error) {
            console.log('error', error)
        }
    }

    // get all task 

    const getAllTask = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-task/?companyID=${companyID}`, config)
            setTaskInfo(data.tasks)
        } catch (error) {
            console.log('error', error)
        }
    }

    // get all expenses 

    const getAllUsersExpress = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-expense/?companyID=${companyID}`, config)
            setExpenseInfo(data.expenses)
        } catch (error) {
            console.log('error', error)
        }
    }

    // get all user Timesheet

    const getAllUserTimesheet = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-Timesheet/?companyID=${companyID}`, config)
            setAllUserTimesheetInfo(data.timesheets)
            setLoading(false)
        } catch (error) {
            console.log('error', error)
        }
    }

    const getAllManager = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-manager/?companyID=${companyID}`, config)

            setAllManager(data.allManager)
        } catch (error) {
            console.log("error from get all user api", error)
        }
    }

    const getAllSubAdmin = async () => {
        try {
            const { data } = await axios.get(`https://dev.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`, config)

            setAllSubAdmin(data.allSubAdmin)
        } catch (error) {
            console.log("error from get all user api", error)
        }
    }


    const filterTimesheetByStatus = async (e) => {
        try {
            const state = e.target.value
            const { data } = await axios.post(`https://dev.timetraker.com/backend/api/all-Filtered-Timesheet/?companyID=${companyID}`, { state: state }, config)
            setAllUserTimesheetInfo(data.filteredTimesheet)
        } catch (error) {
            console.log('error', error)
        }
    }








    let firstFiveItems = projectInfo.slice(0, 5);



    // total number of expense 
    const totalExpense = expenseInfo.length

    // totask number of task 
    const totalProject = projectInfo.length

    // totask number of task 
    const totalTask = taskInfo.length

    // total number of users 

    const totalUser = allUser.length + 1 + allManager.length + allSubAdmin.length


    useEffect(() => {
        if (sendWelcome == true) {
            TimesheetSubmitNotfy()
            setSendWelcome(false)
        }
        getAllUser()
        getAllProject()
        getAllTask()
        getAllUsersExpress()
        getAllUserTimesheet()
        getAllManager()
        getAllSubAdmin()
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false)
        }
    }, [newData])



    // this is all timesheet map code        // starts from here 
    var dateWithHours = {};

    allUserTimesheetInfo ? allUserTimesheetInfo.forEach(item => {
        // item.dynamicData
        let date
        let hours
        if (chartFilter) {
            date = item.Date
            hours = item.Hours
        } else {
            date = item.date
            hours = item.hours
        }
        if (dateWithHours[date]) {
            dateWithHours[date] += hours;
            console.log("dateWithHours", dateWithHours)
        } else {
            dateWithHours[date] = hours;
        }

    }) : console.log("na")

    const newArray = Object.entries(dateWithHours).map(([date, hours]) => ({
        date,
        hours: hours,
    }))

    newArray.sort((a, b) => a.date.localeCompare(b.date));
    // const data = newArray.slice(-4)

    // console.log("newArray", newArray)

    const [currentWeekStart, setCurrentWeekStart] = useState(() => {
        const currentDate = new Date();
        const currentDay = currentDate.getDay(); // 0 (Sun) to 6 (Sat)
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - (currentDay === 0 ? 6 : currentDay - 1)); // Set to the previous Monday
        return startOfWeek;
      });
    
      const getCurrentWeekData = (startOfWeek) => {
        const weekData = [];
        
        for (let i = 0; i < 7; i++) {
          const weekDay = new Date(startOfWeek);
          weekDay.setDate(startOfWeek.getDate() + i);
          
          const formattedDate = weekDay.toISOString().split('T')[0];
          
          const dayData = newArray.find(item => item.date === formattedDate);
          
          weekData.push({
            date: formattedDate,
            hours: dayData ? dayData.hours : 0,
          });
        }
        
        return weekData;
      };
    
      const handlePreviousWeek = () => {
        setCurrentWeekStart(prev => {
          const newDate = new Date(prev);
          newDate.setDate(newDate.getDate() - 7);
          return newDate;
        });
      };
    
      const handleNextWeek = () => {
        setCurrentWeekStart(prev => {
          const newDate = new Date(prev);
          newDate.setDate(newDate.getDate() + 7);
          return newDate;
        });
      };
    
      const data = getCurrentWeekData(currentWeekStart);
    


    const handlechangeFilterDate = (e) => {
        const { name, value } = e.target;
        setDateForFilter((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSubmitFilterData = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(`https://dev.timetraker.com/backend/api/filtered-timesheet-report`, dateForFilter, config)
            setAllUserTimesheetInfo(data.timesheets)
            setChartFilter(true)
        } catch (error) {
            console.log(error)
        }

    }


    const [filteredHoldTimesheet, setFilteredHoldTimesheet] = useState()
    const [filteredSubmitedTimesheet, setFilteredSubmitedTimesheet] = useState()
    const [filteredAprovedTimesheet, setFilteredAprovedTimesheet] = useState()
    const [filteredDownlodedTimesheet, setFilteredDownlodedTimesheet] = useState()
    const [filteredNotDownlodedAndApprovedTimesheet, setFilteredNotDownlodedAndApprovedTimesheet] = useState()
    const [filteredDownlodedExpense, setFilteredDownlodedExpense] = useState()
    const [filteredNotDownlodedAndApprovedExpense, setFilteredNotDownlodedAndApprovedExpense] = useState()


    const [paiDataForSubmitedAndHold, setPaiDataForSubmitedAndHold] = useState([])
    const [paiDataForSubmitedAndApproved, setPaiDataForSubmitedAndApproved] = useState([])
    const [paiDataForApprovedAndDownloded, setPaiDataForApprovedAndDownloded] = useState([])
    const [pendingDownloadTimesheets, setPendingDownloadTimesheets] = useState([])
    const [pendingDownloadExpense, setPendingDownloadExpense] = useState([])


    useEffect(() => {
        setFilteredHoldTimesheet(allUserTimesheetInfo.filter(item => item.state === "hold"))
        setFilteredSubmitedTimesheet(allUserTimesheetInfo.filter(item => item.state === "submited"))
        setFilteredAprovedTimesheet(allUserTimesheetInfo.filter(item => item.state === "aproved"))
        setFilteredDownlodedTimesheet(allUserTimesheetInfo.filter(item => item.sendToQb == true))

        setFilteredNotDownlodedAndApprovedTimesheet(
            allUserTimesheetInfo.filter(item => item.sendToQb === false && item.state === "aproved")
        );

    }, [allUserTimesheetInfo])

    useEffect(() => {
        setFilteredDownlodedExpense(expenseInfo.filter(item => item.sendToQb == true))

        setFilteredNotDownlodedAndApprovedExpense(
            expenseInfo.filter(item => item.sendToQb === false && item.state === "aproved")
        );

    }, [expenseInfo])

    useEffect(() => {

        setPaiDataForSubmitedAndHold([

            {
                name: "Hold",
                value: filteredHoldTimesheet?.length == 0 && filteredSubmitedTimesheet?.length == 0 ? 1 : filteredHoldTimesheet?.length
            },
            {
                name: "Submitted",
                value: filteredSubmitedTimesheet?.length
            }
        ])
    }, [filteredHoldTimesheet, filteredSubmitedTimesheet])

    useEffect(() => {


        setPaiDataForSubmitedAndApproved([
            {
                name: "Submitted",
                value: filteredSubmitedTimesheet?.length == 0 && filteredAprovedTimesheet?.length == 0 ? 1 : filteredSubmitedTimesheet?.length
            },
            {
                name: "Approved",
                value: filteredAprovedTimesheet?.length
            }
        ])
    }, [filteredSubmitedTimesheet, filteredAprovedTimesheet])

    useEffect(() => {


        setPaiDataForApprovedAndDownloded([

            {
                name: "Approved",
                value: filteredAprovedTimesheet?.length == 0 && filteredDownlodedTimesheet?.length == 0 ? 1 : filteredAprovedTimesheet?.length
            },
            {
                name: "Downloded",
                value: filteredDownlodedTimesheet?.length
            }
        ])
    }, [filteredAprovedTimesheet, filteredDownlodedTimesheet])

    useEffect(() => {


        setPendingDownloadTimesheets([

            {
                name: "Pending Download",
                value: filteredNotDownlodedAndApprovedTimesheet?.length == 0 && filteredDownlodedTimesheet?.length == 0 ? 1 : filteredNotDownlodedAndApprovedTimesheet?.length
            },
            {
                name: "Downloded",
                value: filteredDownlodedTimesheet?.length
            }
        ])
    }, [filteredNotDownlodedAndApprovedTimesheet, filteredDownlodedTimesheet])

    useEffect(() => {


        setPendingDownloadExpense([

            {
                name: "Pending Download",
                value: filteredNotDownlodedAndApprovedExpense?.length == 0 && filteredDownlodedExpense?.length == 0 ? 1 : filteredNotDownlodedAndApprovedExpense?.length
            },
            {
                name: "Downloded",
                value: filteredDownlodedExpense?.length
            }
        ])
    }, [filteredNotDownlodedAndApprovedExpense, filteredDownlodedExpense])







    return (
        <AdminLayout>
            {
                loading ?
                    <Loder className='loder' />
                    :
                    console.log("")
            }
            {/* content_right_dashboard */}
            <ToastContainer />

            <Container className={sideBarStatus ? 'content_right_dashboard_3' : "none"} fluid style={sideBarStatus == true & forPhoneScreenNoDisplay == false ? { display: 'none' } : { display: 'block' }}>
                {/* Row 1 */}
                <div className="hamburgar" style={sideBarStatus ? { display: 'none' } : { display: 'block' }}>
                    <i onClick={handleClick} className='fas fa-bars'></i>
                </div>

                <Row>
                    <Col md={12} className='dash-container1' >
                        <div className="header-top">
                            <div className="dash-header1">
                                <Link style={
                                    sideBarStatus == true
                                        ? { paddingLeft: "0px", paddingTop: "2px", fontSize: "22px" }
                                        : { paddingLeft: "30px", paddingTop: "4px", fontSize: "22px" }
                                } >Dashboard</Link>

                            </div>

                            <div className="profile-header2">
                                <ProfileComponent />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className='body_main_content'>
                    {/* Row 2 */}
                    <Row style={{ background: '#F1F1F1' }}>
                        <Col md={12}>
                            <div className="card-container">
                                <div style={{cursor: "pointer"}} onClick={()=>navigate("/manage-user")} className="card1">
                                    <img src={cardImg1} alt="" />
                                    <div className='card_content_parent'>
                                        <p className='card-para1'>{totalUser}</p>
                                        <p className='card-para2'>Users</p>
                                    </div>
                                </div>

                                <div style={{cursor: "pointer"}} onClick={()=>navigate("/project")}  className="card1">
                                    <img src={cardImg2} alt="" />
                                    <div className='card_content_parent'>
                                        <p className='card-para1'>{totalProject}</p>
                                        <p className='card-para2'>Projects</p>
                                    </div>
                                </div>

                                <div style={{cursor: "pointer"}} onClick={()=>navigate("/task")} className="card1">
                                    <img src={cardImg3} alt="" />
                                    <div className='card_content_parent'>
                                        <p className='card-para1'>{totalTask}</p>
                                        <p className='card-para2'>Tasks</p>
                                    </div>
                                </div>

                                <div style={{cursor: "pointer"}} onClick={()=>navigate("/expense-aprove")} className="card1 expense_card">
                                    <img src={cardImg4} alt="" />
                                    <div className='card_content_parent'>
                                        <p className='card-para1'>{totalExpense}</p>
                                        <p className='card-para2'>Expenses </p>
                                    </div>
                                </div>

                                <div style={{cursor: "pointer"}} onClick={()=>navigate("/timesheet-aprove")} className="card1 expense_card">
                                    <AccessTimeFilledIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <div className='card_content_parent'>
                                        <p className='card-para1'>{allUserTimesheetInfo?.length}</p>
                                        <p className='card-para2'>Timesheets</p>
                                    </div>
                                </div>
                                {/* 
                                <div className="card5">
                                    <div className='card-child'>
                                        <p className='card-para3'>Total Time Sheets</p>
                                        <p className='card-para1'>{allUserTimesheetInfo?.length}</p>
                                    </div>
                                </div> */}

                            </div>
                        </Col>
                    </Row>

                    {/* Row 3 */}
                    <Row style={{ background: '#F1F1F1' }}>
                        <Col md={12} className='dash1-wrap1'>
                            <div style={{ height: "439px" }} className='graphLeft'>
                                <div className='graph-text'>
                                    <Form.Select
                                        className="input-menu-focus graph-text_selact"
                                        style={{ cursor: 'pointer' }}
                                        name="status"
                                        aria-label="Default select example"
                                        onChange={filterTimesheetByStatus}
                                    >
                                        <option value="">Total Hours</option>
                                        <option value="hold">On Hold</option>
                                        <option value="submited">Submitted</option>
                                        <option value="aproved">Approved</option>
                                    </Form.Select>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <button style={{ cursor: "text", marginTop: "0px" }} > { chartFilter? `${dateForFilter?.startDate} to ${dateForFilter?.endDate}`   : `${data[0] ? data[0].date : "00-00-00"} to ${data[data.length - 1] ? data[data.length - 1].date : "00-00-00"}`} </button>
                                        <i style={{ cursor: "pointer", color: "#04542C", fontSize: "24px" }} onClick={() => { setShowFilterForChart(!showFilterForChart) }} class="fa-solid fa-calendar-days calander_for_dasboard"></i>
                                        <i onClick={handlePreviousWeek} class="fa-solid fa-circle-left" style={{ color: "#04542c", fontSize: "20px", marginRight: "5px" }}></i>
                                        <i onClick={handleNextWeek} class="fa-solid fa-circle-right" style={{ color: "#04542c", fontSize: "20px" }}></i>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmitFilterData} style={showFilterForChart ? { display: "flex" } : { display: "none" }} className='date_filter_for_cahrt'>
                                    <label>Start</label>
                                    <input required onChange={handlechangeFilterDate} name='startDate' type='date' />
                                    <label>End</label>
                                    <input required onChange={handlechangeFilterDate} name='endDate' type='date' />
                                    <button type='submit' className=''>Filter</button>
                                </form>
                                {/* <img className='img-fluid' src={graphImg} alt="" />  */}
                                <div style={chartFilter ? { display: "none" } : { display: "block" }}>
                                    <AdminChart data={data} />
                                </div>
                                <div style={chartFilter ? { display: "block" } : { display: "none" }}>
                                    <AdminChart data={newArray} />
                                </div>


                            </div>

                            <div className='graphRight-container'>
                                <div className="graphRight">
                                    <Row style={{ background: '#F1F1F1' }} >
                                        <Col md={12} className='dash1-wrap2' style={{ marginBottom: '100px' }} >
                                            <div className="report">

                                                <div className='report-container'>
                                                    <div className="report1">

                                                        <p>Timesheets</p>
                                                        <PaiChartForReport data={pendingDownloadTimesheets} xl={65} />
                                                    </div>
                                                    <div className="report1">

                                                        <p>Expenses</p>
                                                        <PaiChartForReport data={pendingDownloadExpense} xl={65} />
                                                    </div>

                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>



                    {/* Row 4 */}
                    <Row style={{ background: '#F1F1F1' }} >
                        <Col md={12} className='dash1-wrap2' style={{ marginBottom: '100px' }} >
                            <div className="report">
                                <div className='report-text'>
                                    <h5>Reports</h5>
                                    <button onClick={() => { navigate("/timesheet-reports") }} style={{ cursor: 'pointer' }} >More Reports</button>
                                </div>

                                <div className='report-container'>
                                    <div className="report1">
                                        {/*  <p>Budget vs Actual Detail by Employee</p>
                                        <img src={gruop} alt="" />
                                        */}
                                        <p>Submitted Vs Hold Timesheets</p>
                                        <PaiChartForReport data={paiDataForSubmitedAndHold} xl={100} />
                                    </div>
                                    <div className="report1">
                                        {/* <p>Budget vs Actual Detail Summary</p>
                                        <img src={gruop1} alt="" /> */}
                                        <p>Submitted Vs Approved Timesheets</p>
                                        <PaiChartForReport data={paiDataForSubmitedAndApproved} xl={90} />
                                    </div>
                                    <div className="report1">
                                        {/* <p>OverTime Report</p>
                                        <img src={gruop2} alt="" /> */}
                                        <p> Approved Vs Downloded Timesheets</p>
                                        <PaiChartForReport data={paiDataForApprovedAndDownloded} xl={90} />
                                    </div>

                                </div>
                            </div>

                        </Col>
                    </Row>

                </div>
            </Container>
        </AdminLayout>
    )
}

export default AdminDashboardV2
