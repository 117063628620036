import React, { useContext, createContext, useState } from 'react';

const MyContext = createContext();

const MyProvider = ({ children }) => {

  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var setTrueAcordingToScreen = true
  } else {
    var setTrueAcordingToScreen = false
  }


  const [loading, setLoading] = useState(false)
  const [sideBarStatus, setSideBarStatus] = useState(setTrueAcordingToScreen);
  const [reportFilteredData, setReportFilteredData] = useState();
  const [expenseReportFilteredData, setExpenseReportFilteredData] = useState();
  const [sendPass, setSendPass] = useState(false)
  const [sendWelcome, setSendWelcome] = useState(false)
  const [showDemoAlert, setShowDemoAlert] = useState(false)
  const [activeItem, setActiveItem] = useState(null);
  const [activTab, setActivTab] = useState("user");
  const [newUser, setNewUser] = useState();
  const [selectedPropertiesForTimesheet, setSelectedPropertiesForTimesheet] = useState({
    Date: true,
    Task: true,
    Project: true,
    Hours: true,
    Status: true,
    Employee: true,
    Billable: true,
    Description: true,
    Location: true
  });
  const [selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff] = useState({
    Date: true,
    Task: true,
    Balance: true,
    Hours: true,
    Status: true,
    Employee: true
  });
  const [selectedPropertiesForExpense, setSelectedPropertiesForExpense] = useState({
    Date: true,
    Expense: true,
    Project: true,
    Amount: true,
    Status: true,
    Employee: true,
    Billable: true,
    Description: true,
    Location: true
  });
  const [loadingTimesheet, setLoadingTimesheet] = useState(false)
  const [show, setShow] = useState(false)

  const [additionalSettings, setAdditionalSettings] = useState({
    timer: false,
    clockIn: false,
    timesheetApprover: false,
    timesheetClasses: false,
    expenseApprover: false,
    receipt: false
  })


  return (
    <MyContext.Provider value={{ additionalSettings, setAdditionalSettings, newUser, setNewUser, activTab, setActivTab, selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff, show, setShow, loadingTimesheet, setLoadingTimesheet, sideBarStatus, setSideBarStatus, setReportFilteredData, reportFilteredData, setExpenseReportFilteredData, expenseReportFilteredData, loading, setLoading, setSendPass, sendPass, sendWelcome, setSendWelcome, showDemoAlert, setShowDemoAlert, selectedPropertiesForTimesheet, setSelectedPropertiesForTimesheet, selectedPropertiesForExpense, setSelectedPropertiesForExpense, activeItem, setActiveItem }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyProvider, MyContext };
