import React, { useState, useEffect, useContext } from "react";
import "./AdminDashboard.css";
import "./DialogStyle.css";
import { Col, Container, Row } from "react-bootstrap";
import signupImg1 from "../../img/Vector.png";
import signupImg2 from "../../img/user2.png";
import Form from "react-bootstrap/Form";
import UserTimeSheetTable from "../../components/UserTimeSheetTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from "../../context/MyProvider";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import UserPdf from "./UserPdf";
import ImportTimesheet from "../../components/ImportTimesheet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserExcel from "./UserExcel";
import UserCsv from "./UserCsv";
import ProfileComponent from "../../components/ProfileComponent";
import Swal from "sweetalert2";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PunchClockIcon from '@mui/icons-material/PunchClock';
// import TableDesign from "../components/TableDesign"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminDashboard = () => {
  const { sideBarStatus, setSideBarStatus, additionalSettings } = useContext(MyContext);
  const [currentDate2, setCurrentDate2] = useState('');

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false);
    } else {
      setSideBarStatus(true);
    }
  };

  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true;
  } else {
    var forPhoneScreenNoDisplay = false;
  }



  const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
  if (adminloginInfo) {
    var companyID = adminloginInfo?._id;
    var userID = adminloginInfo?._id;
    var token = adminloginInfo?.token
    var userName = adminloginInfo.name;
    var TimesheetLocationTrack = adminloginInfo.accessibility.TimesheetLocationTrack;
  }

  if (userLoginInfo) {
    var companyID = userLoginInfo?.companyID;
    var userID = userLoginInfo?._id;
    var token = userLoginInfo?.token
    var userName = userLoginInfo.name;
    var TimesheetLocationTrack = userLoginInfo.accessibility.TimesheetLocationTrack;
  }


  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  const [formDataForUserFilter, setFormDataForUserFilter] = useState({ userID: userID })
  const [projectInfo, setProjectInfo] = useState([]);
  const [classesInfo, setClassesInfo] = useState([]);
  const [taskInfo, setTaskInfo] = useState([]);
  const [allTaskInfo, setAllTaskInfo] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [renewNewData, setRenewNewData] = useState();
  const [userTimesheetInfo, setUserTimesheetInfo] = useState([]);
  const [newTimesheetData, setNewTimesheetData] = useState();
  const [formData, setFormData] = useState({
    userID: userID,
    companyID: companyID,
    date: currentDate2
  });

  const [isBillable, setIsBillable] = useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [clockin, setClockin] = useState("");

  // location 

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);


  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setError(error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (TimesheetLocationTrack) {
      getLocation();
    }
  }, []);



  const handleChangeBillable = () => {
    setIsBillable((current) => !current);
  };

  const [checkProjectBillable, setCheckProjectBillable] = useState(false);
  const [checkTaskBillable, setCheckTaskBillable] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "project") {
      const data = projectInfo?.filter(item => item.project == value)
      const TaskData = allTaskInfo.find((p) => p.project === data?.[0]?._id);
      setTaskInfo(TaskData ? TaskData.task : [])
      setCheckProjectBillable(data?.[0]?.billable ? data?.[0]?.billable : false)
    }
    if (name === "classDetails") {
      const data = classesInfo?.filter(item => item._id == value)
      setFormData((prevData) => ({
        ...prevData,
        classDetails: {
          _id: data?.[0]?._id,
          class: data?.[0]?.class,
        }
      }));
    }
    if (name === "task") {
      const data = taskInfo?.filter(item => item.task == value)
      setCheckTaskBillable(data?.[0]?.billable ? data?.[0]?.billable : false)
    }
  };

  useEffect(() => {

    if (checkProjectBillable === true || checkTaskBillable === true) {
      setIsBillable(true)
    } else {
      setIsBillable(false)
    }
  }, [checkProjectBillable, checkTaskBillable])

  const handleSubmit = (e) => {
    e.preventDefault();

    let hours = formData.hours
    if (
      formData.task == "" ||
      formData.hours == ""
    ) {
      alert("all the feilds required");
    } else {
      if (hours > 8) {
        var result = window.confirm("Are you sure you want to add more-then 8 hours.");
        if (result) {
          postTimesheetData();
        }
      }
      else if (hours < 1) {
        var result = window.confirm("Are you sure you want to add less-then 1 hour");
        if (result) {
          postTimesheetData();
        }
      } else {
        postTimesheetData();
      }

    }
  };

  // get all project

  const getAllProject = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/user-project/?userID=${userID}`,
        config
      );
      setProjectInfo(data.projects);
    } catch (error) {
      console.log('error', error)
    }
  };

  // get all class

  const getAllClasses = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-active-classes/?companyID=${companyID}`,
        config
      );
      setClassesInfo(data.classes);
    } catch (error) {
      console.log('error', error)
    }
  };

  // get all task

  const getAllTask = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/user-task/?userID=${userID}`,
        config
      );
      setAllTaskInfo(data.tasks);
    } catch (error) {
      console.log('error', error)
    }
  };

  const myClock = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/get-my-clock-in/?userID=${userID}`,
        config
      );
      if (data?.clockIn?.status) {
        setClockin("clockedIn");
      } else {
        setClockin("clockedOut");
      }
    } catch (error) {
      console.log('error', error)
    }
  };

  // post Timesheet data

  const postTimesheetData = async () => {

    if (!formData?.project || formData?.project === "") {
      const checkPolicy = policy.filter((item) => formData.task === item?.policyDetails?.policyName)
      if (checkPolicy.length > 0) {
        const timeOff = true
        createTimesheet(timeOff)
      } else {
        console.log("hello")
        errorNotify("Something went wrong!!")
      }
    } else {
      const timeOff = false
      createTimesheet(timeOff)
    }

    async function createTimesheet(timeOff) {
      try {
        let date = currentDate2
        if (formData.date) {
          date = formData.date
        }

        const lastTimesheetData = {
          formData: formData,
          date: date,
          billable: isBillable
        }
        localStorage.setItem('lastTimesheet', JSON.stringify(lastTimesheetData));


        const { data } = await axios.post(
          `https://dev.timetraker.com/backend/api/create-timesheet-entry`,
          {
            userID: formData.userID,
            companyID: formData.companyID,
            task: formData.task,
            classDetails: formData.classDetails,
            project: formData.project,
            hours: formData.hours,
            name: formData.name,
            date: date,
            billable: isBillable,
            description: formData.description,
            userName: userName,
            location: location,
            timeOff: timeOff
          },
          config
        );
        if (data.locationNull) {
          errorNotify("Please enable location access!!")
          return
        }
        if (data.TimeOffError) {
          errorNotify("You can't request for Time Off right now!!")
          return
        }
        if (data.availableTimeOffError) {
          errorNotify("You don't have sufficient time off!!")
          return
        }
        if (data.holidayError) {
          errorNotify("You can't request for Time Off in holiday")
          return
        }
        if (data.AnotherLocation) {
          errorNotify("You are not in the expected location!")
          return
        }
        setNewTimesheetData(data);
        setFormData({
          task: "",
          project: "",
          hours: "",
          description: "",
          userID: formData.userID,
          companyID: formData.companyID,
          billable: true,
        })
        successNotify('Your timesheet has been added')
        document.getElementById("crateForm").reset()

      } catch (error) {
        console.log("error from post timesheet api", error);
        errorNotify("Something went wrong!!")
      }
    }
  };


  // repeat Timesheet Data 

  const repeatTimesheet = async () => {
    const lastTimesheet = JSON.parse(localStorage.getItem("lastTimesheet"));
    console.log("lastTimesheet", lastTimesheet)
    try {
      let date = currentDate2
      if (formData.date) {
        date = formData.date
      }

      const { data } = await axios.post(
        `https://dev.timetraker.com/backend/api/create-timesheet-entry`,
        {
          userID: lastTimesheet.formData.userID,
          companyID: lastTimesheet.formData.companyID,
          task: lastTimesheet.formData.task,
          project: lastTimesheet.formData.project,
          hours: lastTimesheet.formData.hours,
          name: lastTimesheet.formData.name,
          date: lastTimesheet.date,
          userName: userName,
          billable: lastTimesheet.isBillable,
          description: lastTimesheet.formData.description,
          location: location
        },
        config
      );
      if (data.locationNull) {
        errorNotify("Please enable location access!!")
        return
      }
      if (data.AnotherLocation) {
        errorNotify("You are not in the expected location!")
        return
      }
      setNewTimesheetData(data);
      successNotify('Your timesheet has been added')
    } catch (error) {
      console.log("error from post timesheet api", error);
      alert("facing some error when repeat your Timesheet please enter manually")
    }
  }


  // get all the timesheets of this user

  const getUserTimesheet = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/user-Timesheet/?userID=${userID}`,
        config
      );
      setUserTimesheetInfo(data.timesheets.sort((a, b) => new Date(b.date) - new Date(a.date)));
    } catch (error) {
      console.log("error from get user timesheet api ", error);
    }
  };

  const getUserPolciy = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/get-policy-for-users/?userID=${userID}`,
        config
      );
      if (data) {
        setPolicy(data.allPolicy)
      }
    } catch (error) {
      console.log("error from get user timesheet api ", error);
    }
  };

  const checkRenew = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/renew-time-off/?userID=${userID}`,
        config
      );
      if (data) {
        setRenewNewData(data)
      }
    } catch (error) {
      console.log("error from get user timesheet api ", error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getAllTask();
    myClock()
    getAllProject();
    getAllClasses()
    getUserTimesheet();
    getUserPolciy()
    checkRenew()
    if (!forPhoneScreenNoDisplay) {
      setSideBarStatus(false);
    }
  }, []);

  useEffect(() => {
    getUserTimesheet();
  }, [newTimesheetData]);

  useEffect(() => {
    getUserPolciy()
  }, [setRenewNewData]);

  // timer

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [timerRunning, setTimerRunning] = useState("initialStop");
  const [seconds, setSeconds] = useState(0);

  // Load timer state from local storage on component mount
  useEffect(() => {
    const storedTimerState = JSON.parse(localStorage.getItem("timerState"));

    if (storedTimerState) {
      setSeconds(storedTimerState.seconds);
      setTimerRunning(storedTimerState.timerRunning);
    }
  }, []);

  // Save timer state to local storage on state change
  useEffect(() => {
    localStorage.setItem(
      "timerState",
      JSON.stringify({ seconds, timerRunning })
    );
  }, [seconds, timerRunning]);

  // Effect to handle the case where the timer was running when the page was closed
  useEffect(() => {
    if (timerRunning == "started") {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timerRunning]);

  const onlyPauseTimer = () => {
    if (timerRunning == "started") {
      setTimerRunning("stoped")
    }
  };
  const onlyResumeTimer = () => {
    if (timerRunning == "stoped") {
      setTimerRunning("started")
    }
  };
  const onlyStartTimer = () => {
    if (timerRunning == "initialStop") {
      setTimerRunning("started")
    }
  }
  const onlyStopTimer = () => {
    setTimerRunning("stoped")
    handleClickOpen()
  }
  const resetTimer = () => {
    setSeconds(0);
    setTimerRunning("initialStop");
    handleClose()
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const remainingSeconds = timeInSeconds % 60;
    return `${hours} Hr : ${minutes < 10 ? "0" : ""}${minutes} Min : ${remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds} Sec`;
  };

  var formattedTime = 0.0;
  const formatTime2 = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);

    // Ensure the decimal part has two digits
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Combine hours and formatted minutes
    formattedTime = `${hours}.${formattedMinutes}`;

    return formattedTime;
  };

  const currentDate = new Date();

  // Get the individual components of the date
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Create the formatted date string
  const formattedDate = `${year}-${month}-${day}`;

  const [formData1, setFormData1] = useState({
    userID: userID,
    companyID: companyID,
    date: formattedDate,
    hours: "",
    project: "",
    task: ""
  });

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "classDetails") {
      const data = classesInfo?.filter(item => item._id == value)
      setFormData1((prevData) => ({
        ...prevData,
        classDetails: {
          _id: data?.[0]?._id,
          class: data?.[0]?.class,
        }
      }));
  
    };
    if (name === "project") {
      const data = projectInfo?.filter(item => item.project == value)
      const TaskData = allTaskInfo.find((p) => p.project === data?.[0]?._id);
      setTaskInfo(TaskData ? TaskData.task : [])
    }
  }

  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log("formData1", formData1)
    let hours = formatTime2(seconds)
    if (
      formData1.task == "" ||
      formData1.project == ""
    ) {
      alert("all the feilds required");
    } else {
      if (hours >= 0.001) {
        handleClose()
        postTimesheetData1(hours);
      } else {
        var result = window.confirm("Your have entered lessthen 1 minute. So by default it will take 1 minute entry. Are you want to continue?");
        if (result) {
          hours = 0.001
          postTimesheetData1(hours);
        } else {
          handleClose()
          setFormData1({
            userID: formData1.userID,
            companyID: formData1.companyID,
            date: formattedDate,
            hours: formatTime2(seconds),
            project: "",
            task: "",
            description: ""
          })
        }
      }
    }
  };

  const postTimesheetData1 = async (hours) => {
    console.log("formData1", formData1)
    try {
      const { data } = await axios.post(
        `https://dev.timetraker.com/backend/api/create-timesheet-entry`,
        {
          userID: formData1.userID,
          companyID: formData1.companyID,
          date: formData1.date,
          task: formData1.task,
          project: formData1.project,
          classDetails: formData1.classDetails,
          description: formData1.description,
          hours: hours,
          userName: userName,
          location: location
        },
        config
      );
      if (data.locationNull) {
        errorNotify("Please enable location access!!")
        return
      }
      if (data.AnotherLocation) {
        errorNotify("You are not in the expected location!")
        return
      }
      if (data) {
        setNewTimesheetData(data);
        successNotify('Your timesheet has been added')
        resetTimer()
        setFormData1({
          userID: formData1.userID,
          companyID: formData1.companyID,
          date: formattedDate,
          project: "",
          task: "",
          description: ""
        })
      }
    } catch (error) {
      console.log("error from post timesheet api", error);
    }
  };

  const handleDataFromChild = (data) => {
    setNewTimesheetData(data);
  };

  const makeTheFeildsEmpty = () => {
    setFormData({
      hours: "",
      date: "",
      description: "",
      project: "",
      task: "",
      isBillable: true

    })
  }



  // submitSelectedTimesheets

  const submitSelectedTimesheets = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to submit these Timesheets",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowIds.length < 1) {
            errorNotify("Please select at least 1 Timesheet!!")
          } else {
            const { data } = await axios.post(
              `https://dev.timetraker.com/backend/api/submit-selected-timesheet/`,
              selectedRowIds,
              config
            );
            if (data) {
              setNewTimesheetData(data);
              successNotify('Your timesheet has been submited')
              setSelectedRowIds([])
            }
          }
        } catch (error) {
          console.log("error from submitSelectedTimesheets", error)
        }
      }
    })
  }



  const deleteSelectedTimesheets = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete these Timesheets",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowIds.length < 1) {
            errorNotify("Please select at least 1 Timesheet!!")
          } else {
            const { data } = await axios.post(
              `https://dev.timetraker.com/backend/api/delete-selected-timesheet`,
              selectedRowIds,
              config
            );
            if (data) {
              setNewTimesheetData(data);
              successNotify('Your timesheet has been deleted')
              setSelectedRowIds([])
            }
          }
        } catch (error) {
          console.log("error from submitAllTimesheets", error)
        }
      }
    })
  }



  const handleChangeForFilterForUser = async (e) => {
    const { name, value } = e.target;
    setFormDataForUserFilter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }



  useEffect(() => {
    const filterTimesheetForUser = async () => {

      try {
        const { data } = await axios.post(
          `https://dev.timetraker.com/backend/api/filter-user-timesheet`, { formDataForUserFilter },
          config
        );
        if (data) {
          setUserTimesheetInfo(data.filteredTimesheet.sort((a, b) => new Date(b.date) - new Date(a.date)));
        }
      } catch (error) {
        console.log("error from filteruserTimesheet", error)
      }
    }
    filterTimesheetForUser()
  }, [formDataForUserFilter])

  const clockinUser = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to clock in.",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const { data } = await axios.post(
            `https://dev.timetraker.com/backend/api/clock-in`, {
            userName: userName,
            userID: userID,
            companyID: companyID,
            location: location
          },
            config
          );
          if (data.locationNull) {
            errorNotify("Please enable location access!!")
            return
          }
          if (data.AnotherLocation) {
            errorNotify("You are not in the expected location!")
            return
          }
          if (data.alreadyClockedIn) {
            errorNotify("You are already clocked in!!")
            return
          }
          if (data.clickIn) {
            setClockin("clockedIn");
            successNotify("You are clocked in.")
          }
        } catch (error) {
          errorNotify("Something went wrong!!")
        }
      }
    })
  }

  const clockOutUser = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to clock out.",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "yes"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.post(
            `https://dev.timetraker.com/backend/api/clock-out`, {
            userID: userID,
            location: location,
            companyID: companyID
          },
            config
          );
          if (data.locationNull) {
            errorNotify("Please enable location access!!")
            return
          }
          if (data.AnotherLocation) {
            errorNotify("You are not in the expected location!")
            return
          }
          if (data.alreadyClockedOut) {
            errorNotify("You are already clocked out!!")
            return
          }
          if (data.clickOut) {
            setClockin("clockedOut");
            successNotify("You are clocked out.")
          }
        } catch (error) {
          errorNotify("Something went wrong!!")
        }
      }
    })
  }






  const successNotify = (message) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const errorNotify = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  useEffect(() => {
    // Update the state with the current date when the component mounts
    const formattedDate = new Date().toISOString().split('T')[0];
    setCurrentDate2(formattedDate);
  }, []);

  return (


    <AdminLayout>
      <ToastContainer />
      <div
        className={sideBarStatus ? "content_right_dashboard" : "none"}
        fluid
        style={
          (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <Container fluid className="dash3" style={{ padding: "0" }}>
          {/* Row 1 */}
          <div
            className="hamburgar"
            style={sideBarStatus ? { display: "none" } : { display: "block" }}
          >
            <i onClick={handleClick} className="fas fa-bars"></i>
          </div>

          <Row>
            <Col md={12} className="dash-container2">
              <div className="header-top2">
                <div className="dash-header1">
                  <h5
                    className=""
                    style={
                      sideBarStatus == true
                        ? { paddingLeft: "10px" }
                        : { paddingLeft: "60px" }
                    }
                  >
                    My Time
                  </h5>
                </div>



                <div style={additionalSettings.timer === false ? { display: "none" } : null} className="start_timer_parent">
                  <div style={timerRunning == "initialStop" ? { display: "flex" } : { display: "none" }} onClick={onlyStartTimer} className="header-icon">
                    <p style={{ cursor: "pointer" }}>
                      <i class="fa-sharp fa-solid fa-play"></i>
                      <span>Start Timer</span>
                    </p>
                  </div>
                  <div style={timerRunning != "initialStop" ? { display: "flex" } : { display: "none" }} onClick={onlyStopTimer} className="header-icon">
                    <p style={{ cursor: "pointer" }}>
                      <i class="fa-solid fa-stop"></i>
                      <span>Stop Timer</span>
                    </p>
                  </div>
                  <div style={timerRunning == "initialStop" || timerRunning == "started" ? { display: "flex" } : { display: "none" }} onClick={onlyPauseTimer} className="header-icon">
                    <p style={{ cursor: "pointer" }}>

                      <i class="fa-solid fa-pause"></i>
                      <span>Pause Timer</span>
                    </p>
                  </div>
                  <div style={timerRunning == "stoped" ? { display: "flex" } : { display: "none" }} onClick={onlyResumeTimer} className="header-icon">
                    <p style={{ cursor: "pointer" }}>
                      <i class="fa-sharp fa-solid fa-play"></i>
                      <span>Resume Timer</span>
                    </p>
                  </div>
                </div>

                <div
                  className="dash-header2"
                  style={{ cursor: "context-menu", marginBottom: "10px", display: "flex", alignItems: "center" }}
                >
                  <i style={additionalSettings.timer === false ? { display: "none" } : null} class="fa-solid fa-hourglass-end"></i>{" "}
                  <span style={additionalSettings.timer === false ? { display: "none" } : null} className="hours-tieims"> {formatTime(seconds)}</span>
                  <PunchClockIcon style={additionalSettings.clockIn === false ? { display: "none" } : null} onClick={clockin === "clockedIn" ? clockOutUser : clockinUser} sx={clockin === "clockedIn" ? { color: "#ff0000c4" } : null} className="clock_in" />
                  <ProfileComponent />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-4 timer-second-container">
            <Col>
              <div className="timer-second">
                <div style={timerRunning == "initialStop" ? { display: "flex" } : { display: "none" }} onClick={onlyStartTimer} className="header-icon">
                  <p style={{ cursor: "pointer" }}>
                    <i class="fa-sharp fa-solid fa-play"></i>
                  </p>
                </div>
                <div style={timerRunning != "initialStop" ? { display: "flex" } : { display: "none" }} onClick={onlyStopTimer} className="header-icon">
                  <p style={{ cursor: "pointer" }}>
                    <i class="fa-solid fa-stop"></i>
                  </p>
                </div>
                <div style={timerRunning == "initialStop" || timerRunning == "started" ? { display: "flex" } : { display: "none" }} onClick={onlyPauseTimer} className="header-icon">
                  <p style={{ cursor: "pointer" }}>

                    <i class="fa-solid fa-pause"></i>
                  </p>
                </div>
                <div style={timerRunning == "stoped" ? { display: "flex" } : { display: "none" }} onClick={onlyResumeTimer} className="header-icon">
                  <p style={{ cursor: "pointer" }}>
                    <i class="fa-sharp fa-solid fa-play"></i>
                  </p>
                </div>

                <div className="second" style={{ paddingTop: "10px" }}>

                  <i
                    style={{ color: "#04542C" }}
                    class="fa-solid fa-hourglass-end"
                  ></i>{" "}
                  <span style={{ color: "#04542C", fontSize: " 15px" }}>
                    {" "}
                    {formatTime(seconds)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          {/* Row 2 */}
          <Row style={{ background: "#F1F1F1" }} className="mt-0">
            <Col>
              <div
                className="time"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >

                <div className="text-time">
                  <p
                    style={{ backgroundColor: "#64E48E", cursor: "pointer" }}
                    className="para1"
                  >
                    Day View
                  </p>
                  <p
                    style={{ cursor: "pointer" }}
                    className="para2"
                    onClick={() => {
                      navigate("/weekly-timesheet");
                    }}
                  >
                    Week View
                  </p>
                  <p onClick={() => {
                    navigate("/calendar-timesheet")
                  }} style={{ cursor: "pointer" }} className="para3">
                    Calendar
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          {/* Row 3 */}
          <form
            onSubmit={handleSubmit}
            id="crateForm"
            style={{ background: "#F1F1F1", padding: "0" }}
          >
            <div
              style={{ background: "#F1F1F1" }}
              className="card-container-row"
            >
              <div style={additionalSettings.timesheetClasses === true ? { height: "340px" } : null} className="card-container1">
                <h5>Time</h5>
                <p></p>

                <div action="" className="form-timesheet">
                  <div className="form-time1">
                    <div style={{ width: "45%" }}>
                      <label className="lable_bold" htmlFor="">
                        Hours
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        type="number"
                        value={formData.hours}
                        required
                        onChange={handleInputChange}
                        name="hours"
                        id=""
                      />
                    </div>

                    <div style={{ width: "45%" }}>
                      <label className="lable_bold" htmlFor="">
                        Date
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        required
                        defaultValue={currentDate2}
                        // value={formData.date}
                        onChange={handleInputChange}
                        type="date"
                        name="date"
                        id=""
                      />
                    </div>
                  </div>

                  <div className="textarea">
                    <label className="lable_bold" htmlFor="">
                      Description
                    </label>
                    <textarea

                      style={additionalSettings.timesheetClasses === true ? { height: "96px" } : null}
                      onChange={handleInputChange}
                      value={formData.description}
                      type="text"
                      name="description"
                      id=""
                    ></textarea>
                  </div>
                </div>
              </div>

              <div style={additionalSettings.timesheetClasses === true ? { height: "340px" } : null} className="card-container2">
                <h5>Customer and Job Detail</h5>
                <p></p>
                {/* form  */}
                <div action="" className="form-timesheet">
                  <div className="form-time1">
                    <div style={{ width: "45%" }}>
                      <label className="lable_bold" htmlFor="">
                        Customer:Project
                      </label>
                      <Form.Select
                        className="area"
                        name="project"
                        aria-label="Default select example"
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {projectInfo &&
                          projectInfo.map((item, index) => {
                            return (
                              <option key={index} value={item.project}>
                                {item.project}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>

                    <div style={{ width: "45%" }}>
                      <label className="lable_bold" htmlFor="">
                        Task
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <Form.Select
                        className="area"
                        name="task"
                        aria-label="Default select example"
                        required
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>

                        {formData?.project === "" || !formData?.project ? policy &&
                          policy.map((item, index) => {
                            return (
                              <option key={index} value={item?.policyDetails?.policyName}>
                                {item?.policyDetails?.policyName}
                              </option>
                            );
                          }) :
                          taskInfo.map((item, index) => {
                            return (
                              <option key={index} value={item.task}>
                                {item.task}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </div>

                  <div style={additionalSettings.timesheetClasses === true ? { display: "block", width: "87%", marginTop: "21px", marginLeft: "32px" } : { display: "none" }} >
                    <label className="lable_bold" htmlFor="">
                      Class
                      <i className="fa-sharp fa-light fa-asterisk"></i>
                    </label>
                    <Form.Select
                      className="area"
                      name="classDetails"
                      aria-label="Default select example"
                      required={additionalSettings?.timesheetClasses ? additionalSettings?.timesheetClasses : false}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>

                      {classesInfo &&
                        classesInfo.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.class}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>

                  <div className="checkbox-button">
                    <span style={{ fontSize: "14px", display: "flex", alignItems: "center" }} className="lable_bold">
                      <input
                        style={{ marginRight: "5px" }}
                        value={isBillable}
                        onChange={handleChangeBillable}
                        checked={isBillable}
                        type="checkbox"
                        name="billable"
                        id=""
                      />{" "}
                      Billable
                    </span>
                  </div>

                  <div className="boxContainer">
                    <div className="box-container2">
                      <div
                        className="box1"
                        style={{ cursor: "pointer" }}
                        onClick={makeTheFeildsEmpty}
                        title="Make all the fields empty."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                        >
                          <path
                            d="M9.60218 2.62078C10.0476 2.17554 10.6515 1.92542 11.2813 1.92542C11.9111 1.92542 12.5151 2.17554 12.9604 2.62078L17.5667 7.2271C18.012 7.67247 18.2621 8.27646 18.2621 8.90622C18.2621 9.53599 18.012 10.14 17.5667 10.5853L11.0355 17.1166C10.5902 17.562 9.98621 17.8123 9.35637 17.8125H6.08006C5.45022 17.8123 4.84623 17.562 4.40093 17.1166L1.43218 14.1478C0.98694 13.7025 0.736816 13.0985 0.736816 12.4687C0.736816 11.839 0.98694 11.235 1.43218 10.7896L9.601 2.62078H9.60218ZM10.3859 16.087L4.1005 9.8016L2.27293 11.6292C2.05031 11.8518 1.92525 12.1538 1.92525 12.4687C1.92525 12.7836 2.05031 13.0856 2.27293 13.3083L5.24168 16.277C5.46433 16.4997 5.76633 16.6249 6.08125 16.625H9.35756C9.67248 16.6249 9.97447 16.4997 10.1971 16.277L10.3859 16.087Z"
                            fill="#04542C"
                          />
                        </svg>
                      </div>
                      <div title="Repeat the previous timesheet." onClick={repeatTimesheet} style={{ cursor: "pointer" }} className="box3">
                        <i class="fa-sharp fa-solid fa-rotate"></i>
                      </div>
                    </div>

                    <div className="box-head">
                      <button type="submit" className="box-button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {/* form en  */}
              </div>
            </div>
          </form>

          {/* Row 5 */}

          <Row style={{ background: "#F1F1F1" }}>
            <Col md={12}>
              <div className="time divider">

                <div className="text-time">

                  <Form className="input-menu" >
                    <Form.Select
                      className="input-menu-focus"
                      style={{ cursor: 'pointer' }}
                      name="project"
                      aria-label="Default select example"
                      onChange={handleChangeForFilterForUser}

                    >
                      <option value="">Project</option>
                      {projectInfo &&
                        projectInfo.map((item, index) => {
                          return (
                            <option key={index} value={item.project}>
                              {item.project}
                            </option>
                          );
                        })}
                    </Form.Select>

                    <Form.Select
                      className="input-menu-focus"
                      style={{ cursor: 'pointer' }}
                      name="task"
                      aria-label="Default select example"
                      onChange={handleChangeForFilterForUser}


                    >
                      <option value="">Task</option>
                      {taskInfo &&
                        taskInfo.map((item, index) => {
                          return (
                            <option key={index} value={item.task}>
                              {item.task}
                            </option>
                          );
                        })}
                    </Form.Select>

                    <Form.Select
                      className="input-menu-focus"
                      style={{ cursor: 'pointer' }}
                      name="state"
                      aria-label="Default select example"
                      onChange={handleChangeForFilterForUser}
                    >
                      <option value="">Status</option>
                      <option value="hold">Hold</option>
                      <option value="submited">Submitted</option>
                      <option value="aproved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </Form.Select>


                  </Form>
                </div>

                <div className="button-container">
                  <button className='button-container-button' onClick={submitSelectedTimesheets} style={{ cursor: "pointer" }}>Submit</button>
                  {/* <UserPdf userTimesheetInfo={userTimesheetInfo} /> */}
                  <button className='button-container-button' onClick={handleClickOpen2} style={{ cursor: "pointer" }}>Print</button>
                  <button className='button-container-button' onClick={deleteSelectedTimesheets} style={{ cursor: "pointer" }}>Delete</button>
                  <ImportTimesheet location={location} className='button-container-button' />
                </div>
              </div>
            </Col>
          </Row>

          {/* Row 6 */}
          {/* <Row style={{background: '#F1F1F1'}}>
                <Col md={12}>
                <div className="worksheet2">
                    <ul className="worklist2">
                        <li>Date</li>
                        <li>Customer:Project</li>
                        <li>Task</li>
                        <li>Hours</li>
                        <li>Description</li>
                        <li>Billable</li>
                        <li>Approved or Not</li>
                    </ul>

                    <p className='border1'></p>
                    <p className='border2'></p>
                    <p className='border3'></p>
                
                </div>
                </Col>
            </Row> */}
          <Row style={{ background: "#F1F1F1" }}>
            <Col>
              <div className="user-timesheet-table-parent">
                <UserTimeSheetTable
                  userTimesheetInfo={userTimesheetInfo}
                  setUserTimesheetInfo={setUserTimesheetInfo}
                  handleDataFromChild={handleDataFromChild}
                  selectedRowIds={selectedRowIds}
                  setSelectedRowIds={setSelectedRowIds}
                />
              </div>
            </Col>
          </Row>

          {/* Row 4 */}
        </Container>
      </div>

      {/* diloag  */}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <form
          onSubmit={handleSubmit1}
          className="dialog-main"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <p className="timer">timer</p>
            <DialogActions>
              <Button
                style={{
                  background: "rgb(100, 228, 142)",
                  borderRadius: "10px",
                  color: "#000",
                  fontWeight: "600",
                  padding: "5px 15px",
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </DialogActions>
          </div>

          <div className="timer-button">
            {/* <button className="btn1" onClick={startStopTimer}>
              {timerRunning ? "Stop Timer" : "Start Timer"}
            </button> */}
            <button className="btn2" onClick={resetTimer}>
              Reset Timer
            </button>
          </div>
          <p>{formatTime(seconds)}</p>

          <div className="border-around">
            <div style={{ display: "flex" }} className="form-time1 ">
              <div style={{ width: "50%" }} className="customer">
                <label className="lable_bold" htmlFor="">
                  Customer:Project
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <Form.Select
                  style={{ cursor: "pointer" }}
                  className="area"
                  value={formData1.project}
                  name="project"
                  aria-label="Default select example"
                  required
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  {projectInfo &&
                    projectInfo.map((item, index) => {
                      return (
                        <option key={index} value={item.project}>
                          {item.project}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>

              <div style={{ width: "50%" }} className="task">
                <label className="lable_bold" htmlFor="">
                  Task
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <Form.Select
                  style={{ cursor: "pointer" }}
                  className="area"
                  value={formData1.task}
                  name="task"
                  aria-label="Default select example"
                  required
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>
                  {taskInfo &&
                    taskInfo.map((item, index) => {
                      return (
                        <option key={index} value={item.task}>
                          {item.task}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            </div>

            <div style={{ display: "flex" }} className="form-time1 ">
              <div style={additionalSettings.timesheetClasses === true ? { width: "50%", marginTop: "-8px" } : { display: "none" }} className="task">
                <label className="lable_bold" htmlFor="">
                  Class
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <Form.Select
                  style={{ cursor: "pointer" }}
                  className="area"
                  name="classDetails"
                  aria-label="Default select example"
                  required={additionalSettings?.timesheetClasses ? additionalSettings?.timesheetClasses : false}
                  onChange={handleInputChange1}
                >
                  <option value="">Select</option>

                  {classesInfo &&
                    classesInfo.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.class}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>

              <div style={additionalSettings.timesheetClasses === true ? { width: "50%", marginTop: "3px" } : { width: "100%" }} className="customer">
                <label className="lable_bold" htmlFor="">
                  Description
                </label>

                <textarea style={!additionalSettings.timesheetClasses ? { height: "50px" } : null} value={formData1.description} onChange={handleInputChange1} className="edit_textarea" type="text" name="description" id="">
                  {" "}
                </textarea>
              </div>
            </div>


            <div className="strong">
              <button type="submit" style={{ border: "none", background: "white" }}>
                <strong style={{ display: "flex" }}>
                  Save
                </strong>
              </button>
            </div>
          </div>
        </form>
      </Dialog>


      {/* another dailogBox for the Print  */}

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
          <div style={{ marginBottom: "40px" }} className="button-container">
            <UserPdf userTimesheetInfo={userTimesheetInfo} selectedRowIds={selectedRowIds} />
            <UserExcel userTimesheetInfo={userTimesheetInfo} selectedRowIds={selectedRowIds} />
            <UserCsv userTimesheetInfo={userTimesheetInfo} selectedRowIds={selectedRowIds} />
          </div>
          <div className="button-container">
            <button className='button-container-button' onClick={handleClose2} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
          </div>
        </div>
      </Dialog>
    </AdminLayout>

  );
};


export default AdminDashboard;
