

import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import { Dialog, DialogContent } from '@mui/material';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

const columns = [
    { id: 'task', label: 'Task', minWidth: 100 },
    { id: 'billable', label: 'Billable', minWidth: 100 },
    { id: 'description', label: 'Description', minWidth: 170 },
    {
        id: 'createdAt',
        label: 'Created At',
        minWidth: 170,
        align: 'center'
    },
    { id: 'edit', label: 'Edit', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 }
];

// temporory data

const data = [
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ taskInfo, setTaskInfo, handleDataFromChild }) {

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
        var userName = adminloginInfo.name;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
        var userName = userLoginInfo.name;
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };





    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        companyID: companyID
    });
    const [filteredTask, setFilteredTask] = useState([])


    console.log("formData", formData)
    const handleDailogBoxOpen = async (item) => {
        // setDataNeedToEdit({
        //     _id: _id,
        //     description: description,
        //     task: task, 
        //     project: project, 
        //     date: date, 
        //     hours: hours
        // })

        setFormData({
            _id: item._id,
            task: item.task,
            billable: item.billable,
            description: item.description,
            subTaskOf: item.parentTask?.[item.parentTask.length - 1]?.id
        })

        setFilteredTask(taskInfo.filter(obj =>
            obj._id !== item._id &&
            !obj.parentTask.some(parent => parent.id === item._id)
        ))
        handleClickOpen()
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [reversSort, setReversSort] = useState(true)
    const [sortedColumn, setSortedColumn] = useState(null);


    const handleChangeSort = (field) => {
        const sortedData = [...taskInfo].sort((a, b) => {

            const aValue = a[field];
            const bValue = b[field];

            let comparison = 0;
            if (field === 'date') {
                comparison = new Date(aValue) - new Date(bValue);
            } else if (typeof aValue === 'string') {
                comparison = aValue.localeCompare(bValue);
            } else if (typeof aValue === 'number') {
                comparison = aValue - bValue;
            } else if (typeof aValue === 'boolean') {
                // Handle boolean fields
                comparison = (aValue === bValue) ? 0 : (aValue ? 1 : -1);
            }

            return reversSort ? comparison : -comparison;
        });

        setTaskInfo(sortedData);
        setReversSort(prevToggle => !prevToggle);
        setSortedColumn(field)
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const { data } = await axios.post(
                `https://dev.timetraker.com/backend/api/edit-task`,
                formData,
                config
            );
            if (data.message === "hierarchyError") {
                hierarchyErrorNotify()
            }
            else if (data.message === "lengthError") {
                lengthErrorNotify()
            }
            else if (data) {
                document.getElementById("crateFormTask").reset()
                handleDataFromChild(data);
                TaksEditNotfy()
                handleClose()
            }
        } catch (error) {
            console.log("error from create task api", error);
        }
    };



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // console.log('taskInfo in table', projectInfo.project);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSwitchChange = async (e, taskId) => {
        try {
            const { data } = await axios.post(
                `https://dev.timetraker.com/backend/api/active-task/?taskId=${taskId}`,
                { checked: e.target.checked },
                config
            );
            if (data) {
                handleDataFromChild(data);
                TaskActiveNotfy();
            }

        } catch (err) {
            console.log(err);
        }
    }

    const TaskActiveNotfy = () => {
        toast.success('Task status has been modified', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const hierarchyErrorNotify = () => {
        toast.error('You can not create more hierarchy', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const lengthErrorNotify = () => {
        toast.error('Task size must be between 0 and 100', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const TaksEditNotfy = () => {
        toast.success('Your task has been updated', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" style={{ overflow: 'hidden' }}>
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label === "Edit" ? <div> {column.label} </div> :
                                        <div
                                            onClick={() => handleChangeSort(column.id)}
                                            style={{ display: "flex", cursor: "pointer" }}>
                                            <ArrowDropDownCircleIcon
                                                style={{
                                                    transform: column.id === sortedColumn && !reversSort ? "rotate(180deg)" : "rotate(0deg)",
                                                    margin: "0px 10px 0px 17px",
                                                }}
                                            />
                                            {column.label}
                                        </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'hidden' }}>

                        {taskInfo
                            ? (rowsPerPage > 0 ? taskInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : taskInfo)?.map((item, index) => {
                                const createdAt = new Date(item?.createdAt);

                                const options = {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false,
                                    timeZone: 'Asia/Kolkata'
                                };
                                function shortenString(str) {
                                    if (str?.length > 8) {
                                        return str.substring(0, 8) + '...';
                                    }
                                    return str;
                                }

                                const formattedDate = createdAt.toLocaleString('en-IN', options);
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell title={item?.hierarchy > 0 ? `:: ${item?.task}` : item?.task}>{shortenString(item?.hierarchy > 0 ? `:: ${item?.task}` : item?.task)}</TableCell>
                                        <TableCell>{item?.billable ? "Yes" : "No"}</TableCell>
                                        <TableCell title={item?.description ? item?.description : "Empty"} style={{ textAlign: 'center' }}>{shortenString(item?.description ? item?.description : "Empty")}</TableCell>
                                        <TableCell align="center">{formattedDate}  </TableCell>
                                        <TableCell align="center"><i onClick={() => (handleDailogBoxOpen(item))}
                                            style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }} class="fa-solid fa-pen-to-square userIconsHover"></i></TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.status} onChange={(e) => { handleSwitchChange(e, item._id) }} />}</TableCell>
                                    </TableRow>
                                );
                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell>{item?.task}</TableCell>
                                        <TableCell>{item?.status}</TableCell>
                                        <TableCell align="center">{item?.createdAt}  </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.status} onChange={(e) => { handleSwitchChange(e, item._id) }} />}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={taskInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Row style={{ background: "#fff" }}>
                    <Col md={12}>
                        <div className="adduser peoject_form_padding" style={{ width: "660px", padding: "10px 38px", boxShadow: "none" }}>



                            <form
                                onSubmit={handleSubmit}
                                action=""
                                className="adduser-form"
                                id="crateFormTask"
                                style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                            >


                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div className="form1">
                                        <div className="mb-3 projectInput">
                                            <label className="lable_bold" htmlFor="">
                                                Task
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <input
                                                style={{ minWidth: "240px" }}
                                                value={formData.task}
                                                required
                                                onChange={handleChange}
                                                type="text"
                                                name="task"
                                                id=""
                                            />
                                        </div>


                                    </div>

                                    <div className="form2 ">

                                        <div style={{ marginBottom: "13px", marginLeft: "10px" }} className="role-border projectInput">
                                            <label className="lable_bold" htmlFor="">
                                                Billable
                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                            </label>
                                            <Form.Select
                                                required
                                                value={formData.billable}
                                                onChange={handleChange}
                                                name="billable"
                                                className="role"
                                                aria-label="Default select example"
                                                style={{ marginLeft: "0px" }}
                                            >
                                                <option value="">Select</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </Form.Select>

                                            {/* <label htmlFor="">Role</label>
                                    <input type="text" name="role" id="" /> */}
                                            {/* <i class="fa-solid fa-sort-down"></i> */}
                                        </div>

                                    </div>
                                </div>
                                <div style={{ width: "100%" }} className="role-border projectInput">
                                    <label className="lable_bold" htmlFor="">
                                        Description
                                    </label>
                                    <textarea
                                        onChange={handleChange}
                                        value={formData.description}
                                        name="description"
                                        className="project-textarea" style={{ width: "100%", height: "77px", marginBottom: "31px" }} ></textarea>
                                </div>
                                <div className="adduserBtn ">
                                    <button style={{ marginBottom: "18px" }} type="submit" className="btn5">
                                        Save
                                    </button>
                                </div>

                            </form>
                        </div>
                    </Col>
                </Row>
            </Dialog>
        </Paper>
    );
}