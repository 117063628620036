import React, { useState, useEffect, useContext } from "react";
import "./Task.css";
import signupImg1 from "../../img/Vector.png";
import signupImg2 from "../../img/user2.png";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import TeamTable from "../../components/TeamTable";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import UserPdf from "./teamComponent/UserPdf";
import UserCsv from "./teamComponent/UserCsv";
import UserExcel from "./teamComponent/UserExcel"
import MultiSelect from "../../components/MultiSelect";
import ProfileComponent from "../../components/ProfileComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Team = () => {
  const { sideBarStatus, setSideBarStatus } = useContext(MyContext);


  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false);
    } else {
      setSideBarStatus(true);
    }
  };

  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true;
  } else {
    var forPhoneScreenNoDisplay = false;
  }

  const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
  if (adminloginInfo) {
    var companyID = adminloginInfo?._id;
    var token = adminloginInfo?.token
  }
  if (userLoginInfo) {
    var companyID = userLoginInfo?.companyID;
    var token = userLoginInfo?.token
  }

  const [formData, setFromData] = useState({ companyID: companyID });
  const [teamInfo, setTeamInfo] = useState([]);
  const [newTeam, setNewTeam] = useState();
  const [role, setRole] = useState(null);
  const [user, setUser] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [printTeamData, setPrintTeamData] = useState([]);
  const [printButton, setPrintButton] = useState(false);
  const [userInfo, setuserInfo] = useState([]);

  // config

  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  // const getAllProject = async () => {
  //   try {
  //       const { data } = await axios.get(
  //         `https://dev.timetraker.com/backend/api/all-project/?companyID=${companyID}`,
  //         config
  //       );
  //       setProjectInfo(data.projects);
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // };


  const handleClose = () => {
    setPrintButton(false);
    setPrintTeamData([]);
    setOpen(false);
  }

  const handleOpen = () => {
    setPrintButton(true);
    setOpen(true);
  }

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "role") {
      setRole(value);
    }
  };

  const handleMultiChange = (arr) => {
    const arrayofIds = arr.map((u) => {
      return u._id
    })

    setFromData((prevData) => ({
      ...prevData,
      ["members"]: arrayofIds
    }))

  }

  const getAllUser = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
        config
      );
      return data.allUsers;
    } catch (error) {
      console.log('error from get all user api', error);
      return [];
    }
  };

  const getAllManager = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
        config
      );
      return data.allManager;
    } catch (error) {
      console.log('error from get all manager api', error);
      return [];
    }
  };
  const getAllSubAdmin = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`,
        config
      );
      return data.allSubAdmin;
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };
  const getSuperAdmin = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
        config
      );
      return data.superAdmin;
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };

  const fetchData = async () => {
    const [users, managers, subAdmins, superAdmin] = await Promise.all([getAllUser(), getAllManager(), getAllSubAdmin(), getSuperAdmin()]);
    return [...users, ...managers, ...subAdmins, ...superAdmin]
  };

  useEffect(() => {
    const getusers = async () => {
      if (role === "allManager") {
        const data = await getAllManager()
        setUser(data)
      }
      else if (role === "allSubAdmin") {
        const data = await getAllSubAdmin()
        setUser(data)
      }
      else if (role === "allUsers") {
        const data = await getAllUser()
        setUser(data)
      }
      else if (role === "super-admin") {
        const data = await getSuperAdmin()
        setUser(data)
      }
      else {
        const data = await fetchData()
        setUser(data);
      }
    }
    getusers()
  }, [role]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name) {
      alert("Please Enter Team And Then Add");
    }

    try {
      const { data } = await axios.post(
        `https://dev.timetraker.com/backend/api/add-team`,
        formData,
        config
      );
      if (data) {
        console.log("team", data);
        setFromData({
          companyID: companyID,
          role: "",
          name: "",
          members: [],
          description: ""
        });
        setNewTeam(data);
        setUser([]);
        setRole("");
        TeamSubmitNotfy();
      }
    } catch (err) {
      err.response.status === 422 && TeamExistNotfy();
      setUser([]);
      setRole("");
      setFromData({
        companyID: companyID,
        role: "",
        name: "",
        members: []
      });
      console.log(err);
    }
  }


  useEffect(() => {
    const getPrintData = async () => {
      try {
        teamInfo.map(async (team) => {
          const { data } = await axios.post('https://dev.timetraker.com/backend/api/get-specific-users/', { members: team.members });
          if (data) {

            data.map((d) => {
              const itemData = {
                name: team.name,
                member: d.name,
                createdAt: team.createdAt
              }
              setPrintTeamData((prevData) => ([
                ...prevData,
                itemData
              ]))
            })
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (printButton === true) {
      getPrintData();
    }

  }, [printButton]);

  console.log("printTeamData", printTeamData);


  const getAllTeam = async () => {
    try {
      const { data } = await axios.get(
        `https://dev.timetraker.com/backend/api/all-team/?companyID=${companyID}`,
        config
      );
      setTeamInfo(data);
    } catch (error) {
      console.log("error from team api", error);
    }
  };

  useEffect(() => {
    async function initialDataLoad() {
      getAllTeam();
      const data = await fetchData()
      setAllUsers(data)
    }
    initialDataLoad()
  }, []);
  console.log("teamInfo", teamInfo);
  const handleDataFromChild = (data) => {
    setNewTeam(data);
  };

  useEffect(() => {
    getAllTeam();
    console.log("newTeam", newTeam);
  }, [newTeam]);


  const TeamSubmitNotfy = () => {
    toast.success('Your team has been added', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const TeamExistNotfy = () => {
    toast.success('Team Already Exist', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <AdminLayout>
      <ToastContainer />
      <div
        className={sideBarStatus ? "content_right_dashboard_2" : "none"}
        fluid
        style={
          (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
            ? { display: "none" }
            : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
        }
      >
        <Container fluid className="dash3" style={{ padding: "0" }}>
          {/* Row 1 */}
          <Row>
            <Col className="task-container">
              <div
                className="hamburgar"
                style={
                  sideBarStatus ? { display: "none" } : { display: "block" }
                }
              >
                <i onClick={handleClick} className="fas fa-bars"></i>
              </div>
              <div className="task-top">
                <div className="task-header1">
                  <h5
                    style={
                      sideBarStatus == true
                        ? { paddingLeft: "10px", paddingTop: "2px" }
                        : { paddingLeft: "60px", paddingTop: "4px" }
                    }
                  >
                    Manage  Team
                  </h5>
                </div>

                <div className="profile-header2">
                  <ProfileComponent />
                </div>
              </div>
            </Col>
          </Row>

          {/* Row 2 */}










          <Row style={{ background: "#F1F1F1" }}>
            <Col md={12}>
              <div className="adduser peoject_form_padding" style={{ marginBottom: "27px" }}>
                <div className="adduser-header project-header">
                  <button className="btn1">Team Info</button>
                </div>


                <form
                  onSubmit={handleSubmit}
                  action=""
                  className="adduser-form"
                  id="crateForm"
                  style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                >

                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                      <div className="form1 ">
                        <div style={{ marginBottom: "2rem" }} className=" projectInput">
                          <label className="lable_bold" htmlFor="">
                            Team Name
                            <i className="fa-sharp fa-light fa-asterisk"></i>
                          </label>
                          <input
                            style={{ margin: "0px" }}
                            required
                            value={formData.name}
                            onChange={handleChange}
                            type="text"
                            name="name"
                            id=""
                          />
                        </div>


                        <div style={{ marginBottom: "2rem" }} className="role-border projectInput">
                          <label className="lable_bold" htmlFor="">
                            Role
                          </label>
                          <Form.Select
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className="project_select"
                            aria-label="Default select example"
                            style={{ marginLeft: "0px" }}
                          >
                            <option value="">Select</option>
                            <option value="allSubAdmin">Admin</option>
                            <option value="allManager">Manager</option>
                            <option value="allUsers">User</option>
                            <option value="super-admin">Super Admin</option>
                          </Form.Select>

                          {/* <label htmlFor="">Role</label>
                                    <input type="text" name="role" id="" /> */}
                          {/* <i class="fa-solid fa-sort-down"></i> */}
                        </div>



                      </div>

                      <div className="form2">


                        <div style={{ marginBottom: "2rem" }} className="role-border projectInput">
                          <label className="lable_bold" htmlFor="">
                            Employee
                          </label>
                          <MultiSelect user={user} handleMultiChange={handleMultiChange} />
                        </div>

                        <div style={{ width: "100%", marginBottom: "2rem" }} className=" projectInput">
                          <label className="lable_bold" htmlFor="">
                            Description
                          </label>
                          <textarea
                            onChange={handleChange}
                            value={formData.description}
                            name="description"
                            className="project-textarea" style={{ width: "300px" }} />
                        </div>

                      </div>
                    </div>



                  </div>

                  <div className="adduserBtn ">
                    <button type="submit" className="btn5">
                      Add Team
                    </button>
                  </div>

                </form>
              </div>
            </Col>
          </Row>





          {/* Row 3 */}
          <Row style={{ background: "#F1F1F1" }}>
            <Col>
              <div className="table-container">
                <TeamTable
                  teamInfo={teamInfo}
                  handleDataFromChild={handleDataFromChild}
                  allUsers={allUsers}
                />
              </div>
            </Col>
          </Row>

          {/* dialog box for  printing */}
          {/* <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
              <div style={{ marginBottom: "40px" }} className="button-container">
                <UserPdf teamInfo={printTeamData} />
                <UserExcel teamInfo={printTeamData} />
                <UserCsv teamInfo={printTeamData} />
              </div>
              <div className="button-container">
                <button className='button-container-button' onClick={handleClose} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
              </div>
            </div>
          </Dialog> */}

        </Container>
      </div>
    </AdminLayout>
  );
};

export default Team;
